
import moment from "moment";

export function dateTime(date){
    moment.locale('th');
    return moment(date).format('MMMM Do YYYY, h:mm:ss a');
}

export function dateShow(date){
    moment.locale('th');
    return moment(date).format('MMMM Do YYYY');
}

export function timeShow(date){
    moment.locale('th');
    return moment(date).format('h:mm:ss a');
}


export function ResponseData(response){
    console.log(response)
    return { status:response.status,data:response.data,statusText:response.statusText }
}
