<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container">
      <div class="card_header">
        <div>ผลงานที่เกี่ยวข้องกับวิชาชีพและวิชาเอกที่สำเร็จการศึกษา</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card">
        <div align="right" >
          <button @click="is_edit = !is_edit" class="add_btn" >+</button>
        </div>
        <Transition name="form_transition">
          <v-form
              ref="form"
              v-if="is_edit"
              v-model="valid"
              lazy-validation
          >

            <template>

              <v-file-input
                  @change="Preview_image"
                  :rules=" !this.edit && [v  =>(!v || v.size < 2000000   ||  'กรุณาอัพโหลดรูป (และขนาดไม่เกิน 2MB)') ]"
                  accept="image/png, image/jpeg, image/bmp"
                  placeholder="เลือกรูป"
                  prepend-icon="mdi-camera"
                  label="รูป"
              ></v-file-input>

              <div  class="image_preview_container" >
                <img  id="image_preview" :src=image_preview  />
              </div>

            </template>



            <v-text-field
                v-model="note"
                :rules="[v => !!v || 'กรุณากรอกของ']"
                label="เพิ่มเติม"
                required
            ></v-text-field>



            <v-text-field
                v-model="sort"
                :rules="[value => (value !== '' && value >= 0) || 'กรอกลำดับเป็นตัวเลข']"
                label="ลำดับ (เพื่อใช้ในการจัดเรียงข้อมูลเวลาแสดงผล)"
                required
            ></v-text-field>






            <div align="center" style="margin-top: 2rem">
              <v-btn
                  class="mr-4 update_btn"
                  @click="validate"
              >
                {{ id != 0 ? 'แก้ไข':'เพิ่มข้อมูล' }}
              </v-btn>

              <v-btn
                  class="mr-4 reset_btn"
                  @click="reset"
              >
                ยกเลิก
              </v-btn>

              <v-btn
                  v-if=" id != 0 "
                  class="mr-4 reset_btn"
                  @click="cancle"
              >
                ยกเลิกแก้ไข
              </v-btn>


            </div>

          </v-form>
        </Transition>

        <v-data-table
            :headers="headers"
            :items="resultData"
            :search="search"
            sort-by="calories"
            class="elevation-1"
        >
          <template v-slot:top>
          </template>
          <template v-slot:item.image="{ item }">
            <img v-if="item.image" class="img_thumb" v-bind:src="baseUrl.replace('api','')+''+JSON.parse(item.image).path_thumb" />
            <img v-else class="img_thumb" src="../../assets/image/no-image.jpg" />
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
                small
                class="mr-2"
                @click="editItem(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
                small
                class="mr-2"
                @click="deleteItem(item.id)"
            >
              mdi-trash-can
            </v-icon>

          </template>
          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="GetData()"
            >
              Reset
            </v-btn>
          </template>

        </v-data-table>

      </div>

    </div>
  </v-breadcrumbs>


</template>

<script>

import  {baseUrl } from "../../const/api";
import {getData, postWork,deleteWork} from "../../lib/controller";
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    image: '',
    user_name: '',
    password: '',
    password_confirm: '',
    rfidcode: '',
    is_edit: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ผลงานที่เกี่ยวข้องกับวิชาชีพและวิชาเอกที่สำเร็จการศึกษา',
        disabled: true,
        href: '/works',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'อธิบาย', value: 'note'},
      {text: 'ลำดับ', value: 'sort'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,
    name: "",
    of_: "",
    note: "",
    types: "",
    sort: "",
    gpa: "",
    time: null,
    menu2: false,
    modal2: false,
    date_by: "",
    id: 0,
    image_preview: "",
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  async mounted() {
    this.initialize()
    await this.GetData()
  },
  methods: {
    editItem(item){


      this.image_preview = baseUrl.replace('api','')+''+JSON.parse(item.image).path;

      this.is_edit = true;
      console.log(item)
      this.id = item.id;
      this.note = item.note;
      this.of_ = item.of_;
      this.date_by = item.date_by;
      this.types = item.types;

      if(item.gpa){
        this.gpa = item.gpa;
      }
      this.sort = item.sort;



    },
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      this.id = 0;
    },
    cancle () {
      this.$refs.form.reset()
      this.id = 0;
      this.is_edit = false;
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)


        switch (status){
          case 200:
            console.log(data.profile)

            this.resultData = data.work;


            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("note",this.note);
      if(this.gpa != 'null'){
        formData.append("gpa",this.gpa);
      }

      formData.append("sort",this.sort);
      if(this.id != 0){
        formData.append('is_edit',this.id)
      }
      if(this.file){
        formData.append('image_upload',this.file)
      }





      await postWork(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
              this.reset()
            });


            break;
          default:



            break;
        }

      })

    },
    Preview_image(e) {
      if (e) {
        if(e.size <= 2000000){
          this.file = e;
          this.image_preview = URL.createObjectURL(e);
          //$('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
        }

      }
    },
    async deleteItem(id){
      console.log(id)
      this.$swal({
        icon: 'warning',
        title: 'ต้องการลบใช่หรือไม่ ?',
        showCancelButton: true,
        confirmButtonText: 'ตกลง',
        cancelButtonText:"ยกเลิก",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.delete(id)
        }
      })

      ;
    },
    async delete(id){


      await deleteWork(id,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "ลบสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    }
  },
}


</script>

<style scoped>
.card_container {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card_header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_card {
  padding: 2rem;
}

.image_preview_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#image_preview {
  width: 20rem;
  text-align: center;
}

.custom_table {
  border-collapse: collapse;
}

.table_container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.add_btn{
  font-size: 1.5rem;
  background: #058efa;
  color: white;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card_container{
  transition: 1s;
}
.form_transition-enter-active {
  animation: form_transition 1s ;
}
.form_transition-leave-active {
  animation: form_transition 1s reverse;
}
@keyframes form_transition {
  0% {
    height: 0;
    overflow: hidden;
  }
  100% {
    height: 500px;
    overflow: hidden
  }
}
.img_thumb{
  width: 5rem;
}
</style>
