<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container">
      <div class="card_header">
        <div>ประวัติส่วนตัว</div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-row>
            <v-col xl="4" lg="4" md="4" sm="12">

              <v-text-field
                  v-model="nickname"
                  :rules="[v => !!v || 'กรุณากรอกชื่อเล่น']"
                  label="ชื่อเล่น"
                  required
              ></v-text-field>
            </v-col>
          </v-row>


          <v-row>
            <v-col
                cols="4"
                sm="4"
            >
              <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="birthdate"
                      label="วันเกิด"
                      prepend-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                    v-model="birthdate"
                    :active-picker.sync="activePicker"
                    @change="save"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>


          <v-row>
            <v-col xl="4" lg="4" md="6" sm="12">
              <v-text-field
                  v-model="nation"
                  :rules="[v => !!v || 'กรุณากรอกสัญชาติ']"
                  label="สัญชาติ"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="origin"
                  :rules="[v => !!v || 'กรุณากรอกเชื้อชาติ']"
                  label="เชื้อชาติ"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="religion"
                  :rules="[v => !!v || 'กรุณากรอกศาสนา']"
                  label="ศาสนา"
                  required
              ></v-text-field>
            </v-col>


          </v-row>

          <v-row>

            <v-col xl="6" lg="6" md="6" sm="12">
              <v-text-field
                  v-model="tel"
                  :rules="[v => !!v || 'กรุณากรอกเบอร์โทร']"
                  label="เบอร์โทร"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="6" lg="6" md="6" sm="12">

              <v-text-field
                  v-model="email"
                  :rules="[v => !!v || 'กรุณากรอกอีเมล']"
                  label="อีเมล"
                  required
              ></v-text-field>
            </v-col>

          </v-row>


          <h4>บ้านเลขที่ตามภูมิลำเนา</h4>


          <v-row>
            <v-col xl="4" lg="4" md="6" sm="12">
              <v-text-field
                  v-model="address"
                  :rules="[v => !!v || 'กรุณากรอกบ้านเลขที่']"
                  label="บ้านเลขที่ตามภูมิลำเนา"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="moo"
                  :rules="[v => !!v || 'กรุณากรอกหมู่']"
                  label="หมู่"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="tumbon"
                  :rules="[v => !!v || 'กรุณากรอกตำบล']"
                  label="ตำบล"
                  required
              ></v-text-field>
            </v-col>


          </v-row>


          <v-row>
            <v-col xl="4" lg="4" md="6" sm="12">
              <v-text-field
                  v-model="amphur"
                  :rules="[v => !!v || 'กรุณากรอกสัญชาติ']"
                  label="อำเภอ"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="province"
                  :rules="[v => !!v || 'กรุณากรอกจังหวัด']"
                  label="จังหวัด"
                  required
              ></v-text-field>
            </v-col>

            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="postcode"
                  :rules="[v => !!v || 'กรุณากรอกรหัสไปรษณีย์']"
                  label="รหัสไปรษณีย์"
                  required
              ></v-text-field>
            </v-col>


          </v-row>


          <h4>บ้านเลขที่ปัจจุบัน</h4>
          <v-row>
            <v-col xl="4" lg="4" md="6" sm="12">
              <v-text-field
                  v-model="address_current"
                  :rules="[v => !!v || 'กรุณากรอกบ้านเลขที่']"
                  label="บ้านเลขที่ปัจจุบัน"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="moo_current"
                  :rules="[v => !!v || 'กรุณากรอกหมู่']"
                  label="หมู่"
                  required
              ></v-text-field>
            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="tumbon_current"
                  :rules="[v => !!v || 'กรุณากรอกตำบล']"
                  label="ตำบล"
                  required
              ></v-text-field>
            </v-col>


          </v-row>


          <v-row>
            <v-col xl="4" lg="4" md="6" sm="12">
              <v-text-field
                  v-model="amphur_current"
                  :rules="[v => !!v || 'กรุณากรอกสัญชาติ']"
                  label="อำเภอ"
                  required
              ></v-text-field>


            </v-col>
            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="province_current"
                  :rules="[v => !!v || 'กรุณากรอกจังหวัด']"
                  label="จังหวัด"
                  required
              ></v-text-field>
            </v-col>

            <v-col xl="4" lg="4" md="6" sm="12">

              <v-text-field
                  v-model="postcode_current"
                  :rules="[v => !!v || 'กรุณากรอกรหัสไปรษณีย์']"
                  label="รหัสไปรษณีย์"
                  required
              ></v-text-field>
            </v-col>


          </v-row>


          <v-text-field
              v-model="hobby"
              :rules="[v => !!v || 'กรุณากรอกงานอดิเรก']"
              label="งานอดิเรก"
              required
          ></v-text-field>

          <v-text-field
              v-model="goal_life"
              :rules="[v => !!v || 'กรุณากรอกความมุ่งหมายในชีวิต']"
              label="ความมุ่งหมายในชีวิต "
              required
          ></v-text-field>

          <v-text-field
              v-model="motto"
              :rules="[v => !!v || 'กรุณากรอกคติประจำใจ']"
              label="คติประจำใจ "
              required
          ></v-text-field>


          <h3>ประวัติการศึกษา</h3>
          <br>

          <v-textarea
              outlined
              label="ประถมศึกษา"
              v-model="grad"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="มัธยมศึกษาตอนต้น"
              v-model="grad1"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="มัธยมศึกษาตอนปลาย"
              v-model="grad2"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ระดับปริญญาตรี"
              v-model="degree_1"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="ระดับปริญญาโท"
              v-model="degree_2"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>




          <h4>คำนำ</h4><br>
          <v-textarea
              outlined
              label="คำนำ"
              v-model="introduction"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <hr>

          <div v-if="is_loading" class="img-loading" >
            <img src="./../../assets/image/loading.svg" />
          </div>

          <div v-else align="center" style="margin-top: 2rem" >

            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
              ยกเลิก
            </v-btn><br>
            <br>
            <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>


</template>

<script>
import {getData, postProfile,} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    check: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ประวัติส่วนตัว',
        disabled: true,
        href: '/profile',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    address: "",
    moo: "",
    tumbon: "",
    amphur: "",
    province: "",
    postcode: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    introduction: "",
    nickname: "",
    nation: "",
    origin: "",
    religion: "",
    tel: "",
    email: "",
    address_current: "",
    moo_current: "",
    tumbon_current: "",
    amphur_current: "",
    province_current: "",
    postcode_current: "",
    motto: "",
    goal_life: "",
    hobby: "",
    grad: "",
    grad1: "",
    grad2: "",
    degree_1: "",
    degree_2: "",





    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    birthdate: "",
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted() {
    this.initialize();
   await this.GetData();
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)


        switch (status){
          case 200:
            console.log(data.profile)

            this.is_loading = false;
            this.nickname = data.profile.nickname;
            this.nation = data.profile.nation;
            this.origin = data.profile.origin;
            this.religion = data.profile.religion;
            this.tel = data.profile.tel;
            this.email = data.profile.email;
            this.address = data.profile.address;
            this.moo = data.profile.moo;
            this.tumbon = data.profile.tumbon;
            this.amphur = data.profile.amphur;
            this.province = data.profile.province;
            this.postcode = data.profile.postcode;
            this.address_current = data.profile.address_current;
            this.moo_current = data.profile.moo_current;
            this.tumbon_current = data.profile.tumbon_current;
            this.amphur_current = data.profile.amphur_current;
            this.province_current = data.profile.province_current;
            this.postcode_current = data.profile.postcode_current;
            this.hobby = data.profile.hobby;
            this.goal_life = data.profile.goal_life;
            this.motto = data.profile.motto;

            this.grad = data.profile.grad;
            this.grad1 = data.profile.grad1;
            this.grad2 = data.profile.grad2;
            this.degree_1 = data.profile.degree_1;
            this.degree_2 = data.profile.degree_2;
            this.birthdate = data.profile.birthdate;
            this.introduction = data.profile.introduction;




            this.$refs.topProgress.done()
            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    async Update(){
      this.is_loading = true;
      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("nickname",this.nickname);
      formData.append("nation",this.nation);
      formData.append("origin",this.origin);
      formData.append("religion",this.religion);
      formData.append("tel",this.tel);
      formData.append("email",this.email);
      formData.append("address",this.address);
      formData.append("moo",this.moo);
      formData.append("tumbon",this.tumbon);
      formData.append("amphur",this.amphur);
      formData.append("province",this.province);
      formData.append("postcode",this.postcode);
      formData.append("address_current",this.address_current);
      formData.append("moo_current",this.moo_current);
      formData.append("tumbon_current",this.tumbon_current);
      formData.append("amphur_current",this.amphur_current);
      formData.append("province_current",this.province_current);
      formData.append("postcode_current",this.postcode_current);
      formData.append("hobby",this.hobby);
      formData.append("goal_life",this.goal_life);
      formData.append("motto",this.motto);
      formData.append("grad",this.grad);
      formData.append("grad1",this.grad1);
      formData.append("grad2",this.grad2);
      formData.append("degree_1",this.degree_1);
      formData.append("degree_2",this.degree_2);
      formData.append("birthdate",this.birthdate);
      formData.append("introduction",this.introduction);



      await postProfile(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });

            this.is_loading = false;
            break;
          default:

            this.is_loading = false;

            break;
        }

      })

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container {
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}

.card_header {
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form_card {
  padding: 2rem;
}

.image_preview_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

#image_preview {
  width: 20rem;
  text-align: center;
}

.custom_table {
  border-collapse: collapse;
}

.table_container {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
