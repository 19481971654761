<template>
  <div>
    <div class="page_print">
      <div align="center" ><br><br>
        <font class="header_custom" >แผนพัฒนาตนเองของนักศึกษาครู</font><br>
        <font class="header_custom" >(ID PLAN : INDIVIDUAL DEVELOPMENT PLAN)</font><br><br>
        <font class="header_sub_custom" >ปีการศึกษา 2565</font><br><br><br><br>
        <font class="header_custom" >โดย</font><br><br><br><br>
        <font class="header_custom" >{{ this.student.sex === 'male' ? 'นาย':'นางสาว' }}{{ this.student.first_name }} {{ this.student.last_name }}</font><br><br>
        <font class="header_custom" >สาขาวิชา  {{ this.departments }}</font><br><br><br><br><br><br>
        <font class="header_sub_custom" >โรงเรียน  {{ this.student.relationwork.school ? this.student.relationwork.school.name : "-" }}</font><br><br>
        <font class="header_sub_custom" >สังกัด  {{ this.affiliation }}</font><br><br>
      </div><br>
    </div>


    <div class="page_print">
      <div align="center" ><br><br>
        <font class="header_custom" >คำนำ</font><br>
        <div class="row_print_des">
          <p class="description_intro">
            {{ this.introduction }}
          </p>
        </div><br><br><br>
        <div style="width: 100%;text-align: right;display: flex;justify-content: flex-end;font-size: 1.2rem;" >
          <div align="center" >
            .............................................<br><br>
            (ลงชื่อ) {{ this.student.sex === 'male' ? 'นาย':'นางสาว' }}{{ this.student.first_name }} {{ this.student.last_name }}<br>
            วันที่ &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;เดือน&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;พ.ศ&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<br>
          </div>

        </div>
      </div><br>
    </div>

    <div class="page_print">
      <div align="center" >
        แผนพัฒนาตนเองของนักศึกษาครู <br>
        (ID PLAN : INDIVIDUAL  DEVELOPMENT PLAN)
      </div><br>
      <u>ส่วนที่ 1 ข้อมูลส่วนบุคคล</u><br><br>
      <b>1.1 ประวัติส่วนตัว</b><br>
      <div class="row_print">
        ชื่อ(<del>นาย</del>/นางสาว)
        <div style="width: 286px;text-align: center" class="horizontal_dotted_line">{{ this.student.sex === 'male' ? 'นาย':'นางสาว' }}{{ this.student.first_name }} </div>
        ชื่อสกุล
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ this.student.last_name }}</div>
      </div>
      <div class="row_print">
        รหัสนักศึกษา
        <div style="width: 273px;text-align: center" class="horizontal_dotted_line">{{ this.student.student_code }}</div>
        สาขาวิชา
        <div style="width:299px;text-align: center" class="horizontal_dotted_line">สังคมศึกษาศาสนาและวัฒนธรรม</div>
      </div>
      <div class="row_print">
        ฝึกประสบการณ์วิชาชีพครูโรงเรียน/สถานศึกษา
        <div style="width: 413px;text-align: center" class="horizontal_dotted_line">โรงเรียน{{ this.student.relationwork.school ? this.student.relationwork.school.name : "-" }}</div>
      </div>
      <div class="row_print">
        สังกัด
        <div style="width: 400px;text-align: center" class="horizontal_dotted_line">{{ this.affiliation }}</div>
        กระทรวง
        <div style="width: 295px;text-align: center" class="horizontal_dotted_line">{{ this.ministry }}</div>
      </div>
      <div class="row_print">
        รายวิชา/กลุ่มสาระการเรียนรู้
        <div style="width: 522px;text-align: center" class="horizontal_dotted_line">{{ this.departments }}</div>
      </div>

      1.2 งานในหน้าที่ที่รับผิดชอบ<br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ด้านการจัดการเรียนการสอน


      <div  v-for="index in parseInt(this.work_amount) " :key="index" >


      <div class="row_print">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;1.  กลุ่มสาระการเรียนรู้
        <div style="width: 213px;text-align: center" class="horizontal_dotted_line">{{ subject_group[index-1] }}</div>
        รายวิชา
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ course[index-1] }}</div>
      </div>
      <div class="row_print">
        ชั้น
        <div style="width: 267px;text-align: center" class="horizontal_dotted_line">{{ class_[index-1] }}</div>
        จำนวน
        <div style="width:297px;text-align: center" class="horizontal_dotted_line">{{ periods[index-1] }}</div>คาบ/สัปดาห์
      </div>

      </div>


    </div>

    <div class="page_print" >
      <div class="row_print_des"><br>
        <b>งานที่ได้รับมอบหมายนอกเหนือจากการสอน </b> (ภารกิจที่นอกเหนือจากการสอน เช่น งาน 4 ฝ่ายของสถานศึกษา, กิจกรรมพิเศษ, กิจกรรมพัฒนาผู้เรียน ฯลฯ) <br>
        <p v-html="activity_list"  class="description_raw">
        </p><br>
      </div>
      <div class="row_print_des"><br>
        <b>1.3 ความสามารถพิเศษ </b>
        <p v-html="talent"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        <b>1.4 ผลงาน  ที่เกิดจากการปฏิบัติหน้าที่ในปัจจุบัน (ในระยะ 1 ปี) </b>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;1.  ผลที่เกิดจากการจัดการเรียนรู้
        <p v-html="result_learning"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;2.  ผลที่เกิดจากการพัฒนาวิชาการ
        <p v-html="results_academic_development"  class="description_raw">
        </p><br>
      </div>

    </div>

    <div class="page_print" >

      <div class="row_print_des"><br>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;3.  ผลที่เกิดกับผู้เรียน
        <p v-html="outcomes_students"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;4.  ผลที่เกิดกับสถานศึกษา
        <p v-html="outcomes_school"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;5.  ผลที่เกิดกับชุมชน
        <p v-html="community_effects"  class="description_raw">
        </p><br>
      </div>

    </div>

    <div class="page_print" >
     <b><u>ส่วนที่ 2 ข้อมูลความต้องการในการพัฒนา</u></b>  (กรอกข้อมูลให้ครบถ้วน ตามความเป็นจริง)<br><br>
      <b>2.1	ประเภทหลักสูตรที่ต้องการพัฒนา</b>  (สามารถเลือกได้มากกว่า 1 หลักสูตร หรือระบุหลักสูตรอื่นๆ)<br>

      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_1 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การสอนในศตวรรษที่21 </font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การแก้ปัญหาผู้เรียน</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_3 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) จิตวิทยาการแนะแนว/จิตวิทยาการจัดการเรียนรู้</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_4 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การจัดการชั้นเรียน</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_5 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การวิจัยพัฒนาการเรียนการสอน/ชุมชนแห่งการเรียนรู้ทางวิชาชีพ</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_6 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การพัฒนาหลักสูตร</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_7 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) สะเต็มศึกษา (STEM Education)</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_8 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การใช้สื่อและเทคโนโลยีในการจัดการเรียนรู้</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_9 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การวัดและประเมินผลการเรียนรู้</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_10 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การออกแบบการเรียนรู้</font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_11 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) ทักษะภาษาอังกฤษ</font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_12 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) ทักษะด้านเทคโนโลยีดิจิทัล</font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_13 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การจัดการเรียนการสอนเพื่อฝึกทักษะการคิดแบบมีเหตุผลและเป็นขั้นตอน  หรือ Coding</font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_other == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) อื่นๆ (ระบุ).................{{ }}........</font> <br>

      <b>2.2	รูปแบบวิธีการที่พัฒนา </b>  (สามารถเลือกได้มากกว่า 1 หลักสูตร หรือระบุหลักสูตรอื่นๆ)<br>

      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_1 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) รับการฝึกจากการปฏิบัติงาน (On the Job Training) </font> <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_2 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) รับการสอนงาน (Coaching) จากผู้มีประสบการณ์</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_3 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) รับการฝึกปฏิบัติโดยระบบพี่เลี้ยง (Mentoring)  </font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_4 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) รับการให้คำปรึกษาแนะนำ (Consulting) จากผู้เชี่ยวชาญ</font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_5 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} )  การหมุนเวียนงาน (Job Rotation) ภายในหน่วยงาน / การใหปฏิบัติงานในหน่วยงานอื่น </font>  <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_6 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานต้นสังกัด</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_7 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานภายนอก</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_8 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การดูงานนอกสถานที่ (Site Visit)</font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_9 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การฝึกงานกับผู้เชี่ยวชาญ (Counterpart)  </font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_10 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การมอบหมายงาน (Job Assignment)   </font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_11 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} )การติดตามหัวหนา (Work Shadow)   </font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_12 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การเรียนรูด้วยตนเอง (Self-Learning)    </font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_2_13 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) การเป็นวิทยากรภายในหน่วยงาน (In-House Instructor)   </font>   <br>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <font class="list_check" >( {{ checkbox_other_2 == true ? '✔' : '&nbsp;&nbsp;&nbsp;' }} ) อื่นๆ (ระบุ)........................{{ list_develop }}........................</font> <br>

    </div>


    <div class="page_print" >

      <div class="row_print_des"><br>
        <b>2.3	รายละเอียดจำเพาะหลักสูตรที่ต้องการพัฒนา (ลำดับแรก)  ภายในปีการศึกษา  2566 </b>
       &nbsp;&nbsp; <b>2.3.1	รายละเอียดหลักสูตรใดที่ท่านต้องการพัฒนา (ระบุชื่อหลักสูตร /รายละเอียดเฉพาะ / แหล่งพัฒนา ฯลฯ)</b>
        <p v-html="specific_details_develop"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        &nbsp;&nbsp; <b>2.3.2	เพราะเหตุใดท่านจึงต้องการเข้ารับการพัฒนาในหลักสูตรนี้</b>
        <p v-html="why_want_develop_course"  class="description_raw">
        </p><br>
      </div>




    </div>

    <div class="page_print" >
      <div class="row_print_des"><br>
        &nbsp;&nbsp; <b>2.3.3	ท่านคาดหวังสิ่งใดจากการเข้ารับการพัฒนาในหลักสูตรนี้</b>
        <p v-html="what_do_you_expect"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des"><br>
        &nbsp;&nbsp; <b>2.3.4	ท่านจะนำความรู้จากหลักสูตรไปพัฒนาการสอนของท่านอย่างไร</b>
        <p v-html="how_will_knowledge"  class="description_raw">
        </p><br>
      </div>
    </div>






  </div>
</template>

<script>
import {baseUrl} from "../../const/api";
import {ResponseData} from "../../lib/utility";
import {getData} from "../../lib/controller";
import $ from 'jquery'

export default {
  name: "PlanPrint",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',




    people_select:[],
    school:"",
    affiliation:"",
    ministry:"",
    work_amount:0,
    subject_group:[],
    course:[],
    class_:[],
    periods:[],
    activity_list:"",
    talent:"",
    result_learning:"",
    results_academic_development:"",
    outcomes_students:"",
    outcomes_school:"",
    educational_institutions:"",
    community_effects:"",






    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",
    departments: "",




    checkbox_1:false,
    checkbox_2:false,
    checkbox_3:false,
    checkbox_4:false,
    checkbox_5:false,
    checkbox_6:false,
    checkbox_7:false,
    checkbox_8:false,
    checkbox_9:false,
    checkbox_10:false,
    checkbox_11:false,
    checkbox_12:false,
    checkbox_13:false,
    checkbox_other:false,
    checkbox_2_1:false,
    checkbox_2_2:false,
    checkbox_2_3:false,
    checkbox_2_4:false,
    checkbox_2_5:false,
    checkbox_2_6:false,
    checkbox_2_7:false,
    checkbox_2_8:false,
    checkbox_2_9:false,
    checkbox_2_10:false,
    checkbox_2_11:false,
    checkbox_2_12:false,
    checkbox_2_13:false,
    checkbox_2_14:false,
    checkbox_other_2:false,
    other_1:"",
    other_2:"",
    specific_details_develop:"",
    why_want_develop_course:"",
    what_do_you_expect:"",
    how_will_knowledge:"",
    list_develop:"",



    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    student:{},
    introduction:"",

    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.GetData()
  },
  methods: {
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const class_ = [];
        const course = [];
        const periods = [];
        const subject_group = [];


        switch (status){
          case 200:


            this.checkbox_1 = data.plan_requirement.checkbox_1 === 'true' ? true : false;
            this.checkbox_2 = data.plan_requirement.checkbox_2 === 'true' ? true : false;
            this.checkbox_3 = data.plan_requirement.checkbox_3 === 'true' ? true : false;
            this.checkbox_4 = data.plan_requirement.checkbox_4 === 'true' ? true : false;
            this.checkbox_5 = data.plan_requirement.checkbox_5 === 'true' ? true : false;
            this.checkbox_6 = data.plan_requirement.checkbox_6 === 'true' ? true : false;
            this.checkbox_7 = data.plan_requirement.checkbox_7 === 'true' ? true : false;
            this.checkbox_8 = data.plan_requirement.checkbox_8 === 'true' ? true : false;
            this.checkbox_9 = data.plan_requirement.checkbox_9 === 'true' ? true : false;
            this.checkbox_10 = data.plan_requirement.checkbox_10 === 'true' ? true : false;
            this.checkbox_11 = data.plan_requirement.checkbox_11 === 'true' ? true : false;
            this.checkbox_12 = data.plan_requirement.checkbox_12 === 'true' ? true : false;
            this.checkbox_13 = data.plan_requirement.checkbox_13 === 'true' ? true : false;
            this.checkbox_other = data.plan_requirement.checkbox_other === 'true' ? true : false;
            this.other = data.plan_requirement.other;
            this.checkbox_2_1 = data.plan_requirement.checkbox_2_1 === 'true' ? true : false;
            this.checkbox_2_2 = data.plan_requirement.checkbox_2_2 === 'true' ? true : false;
            this.checkbox_2_3 = data.plan_requirement.checkbox_2_3 === 'true' ? true : false;
            this.checkbox_2_4 = data.plan_requirement.checkbox_2_4 === 'true' ? true : false;
            this.checkbox_2_5 = data.plan_requirement.checkbox_2_5 === 'true' ? true : false;
            this.checkbox_2_6 = data.plan_requirement.checkbox_2_6 === 'true' ? true : false;
            this.checkbox_2_7 = data.plan_requirement.checkbox_2_7 === 'true' ? true : false;
            this.checkbox_2_8 = data.plan_requirement.checkbox_2_8 === 'true' ? true : false;
            this.checkbox_2_9 = data.plan_requirement.checkbox_2_9 === 'true' ? true : false;
            this.checkbox_2_10 = data.plan_requirement.checkbox_2_10 === 'true' ? true : false;
            this.checkbox_2_11 = data.plan_requirement.checkbox_2_11 === 'true' ? true : false;
            this.checkbox_2_12 = data.plan_requirement.checkbox_2_12 === 'true' ? true : false;
            this.checkbox_2_13 = data.plan_requirement.checkbox_2_13 === 'true' ? true : false;
            this.checkbox_other_2 = data.plan_requirement.checkbox_other_2 === 'true' ? true : false;
            this.other_2 = data.plan_requirement.other_2;
            this.specific_details_develop = data.plan_requirement.specific_details_develop;
            this.why_want_develop_course = data.plan_requirement.why_want_develop_course;
            this.what_do_you_expect = data.plan_requirement.what_do_you_expect;
            this.how_will_knowledge = data.plan_requirement.how_will_knowledge;




            this.student = data.data;
            this.affiliation = data.plan.affiliation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.ministry = data.plan.ministry.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.activity_list = data.plan.activity_list.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.talent = data.plan.talent.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.result_learning = data.plan.result_learning.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.results_academic_development = data.plan.results_academic_development.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.outcomes_students = data.plan.outcomes_students.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.outcomes_school = data.plan.outcomes_school.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.community_effects = data.plan.community_effects.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.departments = data.plan.departments.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.introduction = data.profile.introduction.replace(/(?:\r\n|\r|\n)/g, '<br />');




            data.work_list.forEach((result)=>{
              console.log(result)
              class_.push(result.class_)
              course.push(result.course)
              periods.push(result.periods)
              subject_group.push(result.subject_group)
            })

            this.work_amount = course.length;
            this.class_ = class_;
            this.course = course;
            this.periods = periods;
            this.subject_group = subject_group;


            break;
          default:
            this.is_loading = false;
            break;
        }

      });
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
  },
  mounted() {
    //print()
  }
}


</script>

<style scoped>
@media screen{
  .page_print{
    /*display: none;*/
  }

  .table_list tr td{
    padding-left: 10px;
    font-weight: 600;
  }
  .description{
    margin-top:0.5rem;
    font-weight: 600;
    text-indent: 50px;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
  }
  .description_question{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 16px;
    height: 101px;
    text-align: initial;
  }
  .description_raw{
    margin-top:0.5rem;
    font-weight: 600;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
    margin-left: 50px;
  }
  .table_list tr td:first-child{
    padding-left:0;
    text-align: center;
  }
  .horizontal_dotted_line {
    display: flex;
    padding: 5px;
    font-weight: bold;
    height: 2rem;
  }
  .horizontal_dotted_line:after {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .horizontal_dotted_line:before {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .table_list{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history tr{
    background: #f1f1f1;
  }
  .table_list_des{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_des tr th{
    padding-block: 0.3rem;
  }
  .table_list_des tr td{
    padding-block: 0.3rem;
  }
  .row_print{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .row_print_center{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .row_print_des{
    margin-top: 0.5rem;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .page_print{
    font-family: 'Sarabun', sans-serif !important;
    width: 21cm;
    height: 29.7cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .page_print_landscape{
    font-family: 'Sarabun', sans-serif !important;
    width:  29.7cm;
    height: 21cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .header_top_report{
    display: none;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  body{
    font-family: 'Sarabun', sans-serif !important;
    font-size: 15px;
    background: white !important;
    line-height: 1.5;
  }

  .license_list{
    display: flex;
  }
  .license_item{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_custom{
    font-size: 2.2rem;
    font-weight: 300;
  }
  .header_sub_custom{
    font-size: 1.8rem;
    font-weight: 300;
  }
  .description_intro{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 0px !important;
    text-align: inherit;
    font-size: 1.2rem;
  }
  .row_data{
    background: none !important;
  }

  .row_data td{
    height: 4rem;
  }
  .list_check{
    line-height: 1.8rem;
  }
  .license_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }

}

</style>
