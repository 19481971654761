<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>ประวัติการเข้ารับการพัฒนาตนเอง </div>
      </div>

      <v-breadcrumbs :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >


        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <h2>ข้อมูลความต้องการในการพัฒนา </h2>

          <h3>ประเภทหลักสูตรที่ต้องการพัฒนา (สามารถเลือกได้มากกว่า 1 หลักสูตร หรือระบุหลักสูตรอื่นๆ) </h3><br>

          <v-checkbox
              v-model="checkbox_1"
              label="การสอนในศตวรรษที่21"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2"
              label="การแก้ปัญหาผู้เรียน "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_3"
              label="จิตวิทยาการแนะแนว/จิตวิทยาการจัดการเรียนรู้  "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_4"
              label="การจัดการชั้นเรียน "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_5"
              label="การวิจัยพัฒนาการเรียนการสอน/ชุมชนแห่งการเรียนรู้ทางวิชาชีพ "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_6"
              label="การพัฒนาหลักสูตร "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_7"
              label="สะเต็มศึกษา (STEM Education)  "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_8"
              label="การใช้สื่อและเทคโนโลยีในการจัดการเรียนรู้ "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_9"
              label="การวัดและประเมินผลการเรียนรู้"
          ></v-checkbox>


          <v-checkbox
              v-model="checkbox_10"
              label="การออกแบบการเรียนรู้"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_11"
              label="ทักษะภาษาอังกฤษ"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_12"
              label="ทักษะด้านเทคโนโลยีดิจิทัล"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_13"
              label="การจัดการเรียนการสอนเพื่อฝึกทักษะการคิดแบบมีเหตุผลและเป็นขั้นตอน  หรือ Coding"
          ></v-checkbox>


          <div class="list_check_box" >
            <v-checkbox
                v-model="checkbox_other"
                label="อื่นๆ"
            ></v-checkbox>

            <v-text-field
                v-if="checkbox_other"
                v-model="other"
                :rules="[v => !!v || 'กรอกนากรอกอื่นๆ']"
                label="อื่นๆ"
                required
            ></v-text-field>

          </div>

          <h3>รูปแบบวิธีการที่พัฒนา (สามารถเลือกได้มากกว่า 1 วิธีการ หรืออื่นๆโปรดระบุ) </h3><br>


          <v-checkbox
              v-model="checkbox_2_1"
              label="รับการฝึกจากการปฏิบัติงาน (On the Job Training)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_2"
              label="รับการสอนงาน (Coaching) จากผู้มีประสบการณ์"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_3"
              label="รับการฝึกปฏิบัติโดยระบบพี่เลี้ยง (Mentoring)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_4"
              label="รับการให้คำปรึกษาแนะนำ (Consulting) จากผู้เชี่ยวชาญ"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_5"
              label="การหมุนเวียนงาน (Job Rotation) ภายในหน่วยงาน / การให้ปฎิบัติงานในหน่วยงานอื่น"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_6"
              label="การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานต้นสังกัด"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_7"
              label="การเข้าร่วมประชุม / สัมมนา (Meeting / Seminar) จากหน่วยงานภายนอก"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_8"
              label="การดูงานนอกสถานที่ (Site Visit)"
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_9"
              label="การฝึกงานกับผู้เชี่ยวชาญ (Counterpart) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_10"
              label="การมอบหมายงาน (Job Assignment) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_11"
              label="การติดตามหัวหน้า (Work Shadow) "
          ></v-checkbox>

          <v-checkbox
              v-model="checkbox_2_12"
              label="การเรียนรู้ด้วยตนเอง (Self-Learning) "
          ></v-checkbox>


          <v-checkbox
              v-model="checkbox_2_13"
              label="การเป็นวิทยากรภายในหน่วยงาน (In-House Instructor)"
          ></v-checkbox>

          <div class="list_check_box" >
            <v-checkbox
                v-model="checkbox_other_2"
                label="อื่นๆ"
            ></v-checkbox>
ุ
            <v-text-field
                v-if="checkbox_other_2"
                v-model="list_develop"
                :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                label="อื่นๆ"
                required
            ></v-text-field>

          </div>

          <v-textarea
              outlined
              label="รายละเอียดจำเพาะหลักสูตรที่ต้องการพัฒนา "
              v-model="specific_details_develop"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="เพราะเหตุใดท่านจึงต้องการเข้ารับการพัฒนาในหลักสูตรนี้ "
              v-model="why_want_develop_course"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ท่านคาดหวังสิ่งใดจากการเข้ารับการพัฒนาในหลักสูตรนี้ "
              v-model="what_do_you_expect"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="ท่านจะนำความรู้จากหลักสูตรไปพัฒนาการสอนของท่านอย่างไร "
              v-model="how_will_knowledge"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>



          <div align="center" style="margin-top: 2rem" >
            <v-btn
                class="mr-4 update_btn"
                @click="validate"
            >
              บันทึก
            </v-btn>

            <v-btn
                class="mr-4 reset_btn"
                @click="reset"
            >
             ยกเลิก
            </v-btn>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, postPlanRequirement} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'ข้อมูลความต้องการในการพัฒนา ',
        disabled: true,
        href: '/plan_requirement',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    first_name:"",
    last_name:"",
    sex:"",
    course_select:1,
    cours_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    list_develop:"",
    affiliation:"",
    date_start:"",
    date_end:"",
    checkbox_1:false,
    checkbox_2:false,
    checkbox_3:false,
    checkbox_4:false,
    checkbox_5:false,
    checkbox_6:false,
    checkbox_7:false,
    checkbox_8:false,
    checkbox_9:false,
    checkbox_10:false,
    checkbox_11:false,
    checkbox_12:false,
    checkbox_13:false,
    checkbox_other:false,
    checkbox_2_1:false,
    checkbox_2_2:false,
    checkbox_2_3:false,
    checkbox_2_4:false,
    checkbox_2_5:false,
    checkbox_2_6:false,
    checkbox_2_7:false,
    checkbox_2_8:false,
    checkbox_2_9:false,
    checkbox_2_10:false,
    checkbox_2_11:false,
    checkbox_2_12:false,
    checkbox_2_13:false,
    checkbox_2_14:false,
    checkbox_other_2:false,
    other_1:"",
    other_2:"",
    specific_details_develop:"",
    why_want_develop_course:"",
    what_do_you_expect:"",
    how_will_knowledge:"",


    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted () {
   this.initialize()
   await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const class_ = [];
        const course = [];
        const periods = [];
        const subject_group = [];

        switch (status){
          case 200:

            console.log("----------plan_requirement------------")

            this.checkbox_1 = data.plan_requirement.checkbox_1 === 'true' ? true : false;
            this.checkbox_2 = data.plan_requirement.checkbox_2 === 'true' ? true : false;
            this.checkbox_3 = data.plan_requirement.checkbox_3 === 'true' ? true : false;
            this.checkbox_4 = data.plan_requirement.checkbox_4 === 'true' ? true : false;
            this.checkbox_5 = data.plan_requirement.checkbox_5 === 'true' ? true : false;
            this.checkbox_6 = data.plan_requirement.checkbox_6 === 'true' ? true : false;
            this.checkbox_7 = data.plan_requirement.checkbox_7 === 'true' ? true : false;
            this.checkbox_8 = data.plan_requirement.checkbox_8 === 'true' ? true : false;
            this.checkbox_9 = data.plan_requirement.checkbox_9 === 'true' ? true : false;
            this.checkbox_10 = data.plan_requirement.checkbox_10 === 'true' ? true : false;
            this.checkbox_11 = data.plan_requirement.checkbox_11 === 'true' ? true : false;
            this.checkbox_12 = data.plan_requirement.checkbox_12 === 'true' ? true : false;
            this.checkbox_13 = data.plan_requirement.checkbox_13 === 'true' ? true : false;
            this.checkbox_other = data.plan_requirement.checkbox_other === 'true' ? true : false;
            this.other = data.plan_requirement.other;
            this.checkbox_2_1 = data.plan_requirement.checkbox_2_1 === 'true' ? true : false;
            this.checkbox_2_2 = data.plan_requirement.checkbox_2_2 === 'true' ? true : false;
            this.checkbox_2_3 = data.plan_requirement.checkbox_2_3 === 'true' ? true : false;
            this.checkbox_2_4 = data.plan_requirement.checkbox_2_4 === 'true' ? true : false;
            this.checkbox_2_5 = data.plan_requirement.checkbox_2_5 === 'true' ? true : false;
            this.checkbox_2_6 = data.plan_requirement.checkbox_2_6 === 'true' ? true : false;
            this.checkbox_2_7 = data.plan_requirement.checkbox_2_7 === 'true' ? true : false;
            this.checkbox_2_8 = data.plan_requirement.checkbox_2_8 === 'true' ? true : false;
            this.checkbox_2_9 = data.plan_requirement.checkbox_2_9 === 'true' ? true : false;
            this.checkbox_2_10 = data.plan_requirement.checkbox_2_10 === 'true' ? true : false;
            this.checkbox_2_11 = data.plan_requirement.checkbox_2_11 === 'true' ? true : false;
            this.checkbox_2_12 = data.plan_requirement.checkbox_2_12 === 'true' ? true : false;
            this.checkbox_2_13 = data.plan_requirement.checkbox_2_13 === 'true' ? true : false;
            this.checkbox_other_2 = data.plan_requirement.checkbox_other_2 === 'true' ? true : false;
            this.other_2 = data.plan_requirement.other_2;
            this.specific_details_develop = data.plan_requirement.specific_details_develop;
            this.why_want_develop_course = data.plan_requirement.why_want_develop_course;
            this.what_do_you_expect = data.plan_requirement.what_do_you_expect;
            this.how_will_knowledge = data.plan_requirement.how_will_knowledge;





            data.work_list.forEach((result)=>{
              console.log(result)
              class_.push(result.class_)
              course.push(result.course)
              periods.push(result.periods)
              subject_group.push(result.subject_group)
            })

            this.work_amount = course.length;
            this.class_ = class_;
            this.course = course;
            this.periods = periods;
            this.subject_group = subject_group;

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
    async Update(){

      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("student_id",this.users.id);
      formData.append("checkbox_1",this.checkbox_1);
      formData.append("checkbox_2",this.checkbox_2);
      formData.append("checkbox_3",this.checkbox_3);
      formData.append("checkbox_4",this.checkbox_4);
      formData.append("checkbox_5",this.checkbox_5);
      formData.append("checkbox_6",this.checkbox_6);
      formData.append("checkbox_7",this.checkbox_7);
      formData.append("checkbox_8",this.checkbox_8);
      formData.append("checkbox_9",this.checkbox_9);
      formData.append("checkbox_10",this.checkbox_10);
      formData.append("checkbox_11",this.checkbox_11);
      formData.append("checkbox_12",this.checkbox_12);
      formData.append("checkbox_13",this.checkbox_13);
      formData.append("checkbox_other",this.checkbox_other);
      formData.append("other",this.other);
      formData.append("checkbox_2_1",this.checkbox_2_1);
      formData.append("checkbox_2_2",this.checkbox_2_2);
      formData.append("checkbox_2_3",this.checkbox_2_3);
      formData.append("checkbox_2_4",this.checkbox_2_4);
      formData.append("checkbox_2_5",this.checkbox_2_5);
      formData.append("checkbox_2_6",this.checkbox_2_6);
      formData.append("checkbox_2_7",this.checkbox_2_7);
      formData.append("checkbox_2_8",this.checkbox_2_8);
      formData.append("checkbox_2_9",this.checkbox_2_9);
      formData.append("checkbox_2_10",this.checkbox_2_10);
      formData.append("checkbox_2_11",this.checkbox_2_11);
      formData.append("checkbox_2_12",this.checkbox_2_12);
      formData.append("checkbox_2_13",this.checkbox_2_13);
      formData.append("checkbox_other_2",this.checkbox_other_2);
      formData.append("other_2",this.other_2);
      formData.append("specific_details_develop",this.specific_details_develop);
      formData.append("why_want_develop_course",this.why_want_develop_course);
      formData.append("what_do_you_expect",this.what_do_you_expect);
      formData.append("how_will_knowledge",this.how_will_knowledge);


      await postPlanRequirement(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });


            break;
          default:



            break;
        }

      })

    },
    initialize () {

    },

    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },

    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.list_check_box{
  display: flex;
  gap: 20px;
}
</style>
