<template>
  <div>
    <div class="page_print">
      <div align="center" >
        ประวัติส่วนตัว(profile)
      </div><br>
      <div class="row_print">
        ชื่อ
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.student.first_name }}</div>
        นามสกุล
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ this.student.last_name }}</div>
      </div>
      <div class="row_print">
        ชื่อเล่น
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.profile.nickname }}</div>
      </div>

      <div class="row_print">
        เกิดวันที่
        <div style="width: 250px;text-align: center" class="horizontal_dotted_line">{{ GetDate(this.profile.birthdate,0) }}</div>
        เดือน
        <div style="width: 150px;text-align: center" class="horizontal_dotted_line">{{ GetDate(this.profile.birthdate,1) }}</div>
        พุทธศักราช
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">{{ GetDate(this.profile.birthdate,2) }}</div>
      </div>

      <div class="row_print">
        อายุ
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.profile.nickname }}</div>
      </div>

      <div class="row_print">
        สัญชาติ
        <div style="width: 250px;text-align: center" class="horizontal_dotted_line">{{ this.profile.nation }}</div>
        เชื้อชาติ
        <div style="width: 150px;text-align: center" class="horizontal_dotted_line">{{ this.profile.origin }}</div>
        ศาสนา
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">{{ this.profile.religion }}</div>
      </div>

      <div class="row_print">
        เบอร์โทรศัพท์
        <div style="width: 309px;text-align: center" class="horizontal_dotted_line">{{ this.profile.tel }}</div>
        E-mail
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ this.profile.email }}</div>
      </div>


      <div class="row_print">
        ภูมิลำเนาบ้านเลขที่
        <div style="width: 230px;text-align: center" class="horizontal_dotted_line">{{ this.profile.address }}</div>
        หมู่
        <div style="width: 150px;text-align: center" class="horizontal_dotted_line">{{ this.profile.moo }}</div>
        ตำบล
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">{{ this.profile.tumbon }}</div>
      </div>

      <div class="row_print">
        อำเภอ
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.profile.amphur }}</div>
        จังหวัด
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ this.profile.province }}</div>
      </div>



      <div class="row_print">
        สถานที่อยู่ปัจจุบันตามทะเบียนบ้านเลขที่
        <div style="width: 80px;text-align: center" class="horizontal_dotted_line">{{ this.profile.address_current }}</div>
        หมู่
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ this.profile.moo_current }}</div>
        ตำบล
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">{{ this.profile.tumbon_current }}</div>
      </div>


      <div class="row_print">
        อำเภอ
        <div style="width: 250px;text-align: center" class="horizontal_dotted_line">{{ this.profile.amphur_current }}</div>
        จังหวัด
        <div style="width: 150px;text-align: center" class="horizontal_dotted_line">{{ this.profile.province_current }}</div>
        รหัสไปรษณีย์
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">{{ this.profile.postcode_current }}</div>
      </div>


      <div class="row_print">
        งานอดิเรก
        <div style="width: 640px;text-align: center" class="horizontal_dotted_line">{{ this.profile.hobby }}</div>
      </div>

      <div class="row_print">
        ความมุ่งหมายในชีวิต
        <div style="width: 580px;text-align: center" class="horizontal_dotted_line">{{ this.profile.goal_life }}</div>
      </div>


      <div class="row_print">
        คติประจำใจ
        <div style="width: 635px;text-align: center" class="horizontal_dotted_line">{{ this.profile.motto }}</div>
      </div>


    </div>
    <div class="page_print">
        <div align="center" >
          ประวัติการศึกษา
        </div><br>

      <div class="row_print_des">
        ประถมศึกษา<br>
        <p v-html="grad"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        มัธยมศึกษาตอนต้น<br>
        <p v-html="grad1"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        มัธยมศึกษาตอนปลาย<br>
        <p v-html="grad2"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        ระดับปริญญาตรี<br>
        <p v-html="degree_1"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        ระดับปริญญาโท<br>
        <p v-html="degree_2"  class="description_raw">
        </p><br>
      </div>

    </div>

    <div class="page_print"  v-for="result in certificate" :key="result.id" >
      <div align="center" >
        <img v-if="result.image" class="img_cer" v-bind:src="baseUrl.replace('api','')+''+JSON.parse(result.image).path" />
        <img v-else class="img_thumb" src="../../assets/image/no-image.jpg" />
      </div>
      <div align="center" >
        <h2>{{ result.types == "grade" ? "ใบรายงานผลการศึกษา" : "ใบรับรองคุณวุฒิ" }}</h2>
      </div>


      <div class="row_print_center " align="center" >
        ของ
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ result.of_ }}</div>
      </div>

      <div class="row_print_center">
        ให้ไว้ ณ วันที่
        <div style="width: 80px;text-align: center" class="horizontal_dotted_line">{{ GetData(result.date_by) }}</div>
        เดือน
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">-</div>
        พุทธศักราช
        <div style="width: 140px;text-align: center" class="horizontal_dotted_line">-</div>
      </div>

      <div v-if="result.types == 'grade' " class="row_print_center " align="center" >
        เกรดเฉลี่ย
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ result.gpa }}</div>
      </div>



    </div>


      <div class="page_print" v-for="result in course" :key="result.id"   >
        <div v-for="results in result" :key="results.id" >
          <div align="center" >
            <img v-if="results.image" :class="CheckSizeClass(baseUrl.replace('api','')+''+JSON.parse(results.image).path)" class="img_cer" v-bind:src="baseUrl.replace('api','')+''+JSON.parse(results.image).path" />
            <img v-else class="img_thumb" src="../../assets/image/no-image.jpg" />
          </div>
          <br>
          <p class="description_note" >{{ results.note }}</p>

        </div>

      </div>


    <div class="page_print" v-for="result in work" :key="result.id"   >
      <div v-for="results in result" :key="results.id" >
        <div align="center" >
          <img v-if="results.image" :class="CheckSizeClass(baseUrl.replace('api','')+''+JSON.parse(results.image).path)" class="img_cer" v-bind:src="baseUrl.replace('api','')+''+JSON.parse(results.image).path" />
          <img v-else class="img_thumb" src="../../assets/image/no-image.jpg" />
        </div>
        <br>
        <p class="description_note" >{{ results.note }}</p>

      </div>

    </div>


    <div class="page_print" v-for="result in work_community" :key="result.id"   >
      <div v-for="results in result" :key="results.id" >
        <div align="center" >
          <img v-if="results.image"  class="img_cer" :class="CheckSizeClass(baseUrl.replace('api','')+''+JSON.parse(results.image).path)" v-bind:src="baseUrl.replace('api','')+''+JSON.parse(results.image).path" />
          <img v-else class="img_thumb" src="../../assets/image/no-image.jpg" />
        </div>
        <br>
        <p class="description_note" >{{ results.note }}</p>

      </div>

    </div>





  </div>
</template>

<script>
import {getData} from "../../lib/controller";
import {ResponseData} from "../../lib/utility";
import {baseUrl} from "../../const/api";

import $ from 'jquery'
import moment from "moment";

export default {
  name: "ProfilePrint",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    users:JSON.parse(localStorage.getItem('user')),
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    student: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',
    profile: '',



    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",
    plan_design: "",
    total_hour: "",
    departments: "",
    name_visitor: "",
    type_visitor: "",

    date_1: "",
    time_1: "",
    date_2: "",
    time_2: "",
    class_name_2: "",
    amount_student_2: "",
    people_amount_2:0,
    department_select: "",
    well_done: "",
    not_on_target: "",
    improve: "",
    student_achieve: "",
    howto_fix: "",
    total_hour_2: "",
    departments_2: "",
    name_visitor_2: "",
    type_visitor_2: "",

    indication_of_knowledge:"",
    creating_knowledge:"",
    knowledge_into_system:"",
    filtering_knowledge:"",
    access_knowledge:"",
    knowledge_sharing:"",
    learning:"",
    work_name:"",
    goal_of_event:"",
    summary:"",
    good_job_step:"",
    poor_job_step:"",
    obstacles_limitations:"",
    learned_issues:"",
    guidelines_next_job:"",


    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],


    grad: "",
    grad1: "",
    grad2: "",
    degree_1: "",
    degree_2: "",


    course:[],
    certificate: [],
    work: [],
    work_community: [],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    dateShow(date){
      moment.locale('th');
      return moment(date).format('Do MMMM YYYY');
    },
    timeShow(date){
      moment.locale('th');
      return moment(date).format('h:mm:ss a');
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        Array.prototype.chunk = function(size) {
          let result = [];

          while(this.length) {
            result.push(this.splice(0, size));
          }

          return result;
        }


        switch (status){
          case 200:


            this.is_loading = false;
            this.student = data.data;
            this.profile = data.profile;

            this.grad = data.profile.grad.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.grad1 = data.profile.grad1.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.grad2 = data.profile.grad2.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.degree_1 = data.profile.degree_1.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.degree_2 = data.profile.degree_2.replace(/(?:\r\n|\r|\n)/g, '<br />');

            this.course = data.course.chunk(2)
            this.certificate = data.certificate;
            this.work = data.work.chunk(2)
            this.work_community = data.work_community.chunk(2)



            break;
          default:
            this.is_loading = false;
            break;
        }

      });
    },
    async CheckSizeClass(urlimage){

    let res  = await this.getMeta(urlimage);
    if(res.naturalHeight > res.naturalWidth){
      return "port_img";
    }else{
      return "land_img";
    }


    },
    async  getMeta(url){

    return new Promise((resolve,reject) => {

      const img = new Image();

      img.onload = () => resolve( img);
      img.onerror = (err) => reject(err);
      img.src = url;

    });

  },
  GetDate(date,index){
      moment.locale('th');
      console.log(index)
      let date_ = moment(date).format('Do MMMM YYYY')
      const dateArray = date_.split(" ");
      if(index == 2){
        return (parseInt(dateArray[index])+543);
      }else{
        return dateArray[index];
      }

  },
},
  async mounted() {
    await this.GetData()
    //print()
  }
}


</script>

<style scoped>

.img_cer{
  max-height: 400px;
}
.description_note{
  text-align: left;
}

@media screen{
  .page_print{
    /*display: none;*/
  }

  .table_list tr td{
    padding-left: 10px;
    font-weight: 600;
  }
  .description{
    margin-top:0.5rem;
    font-weight: 600;
    text-indent: 50px;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
  }
  .description_question{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 16px;
    height: 101px;
    text-align: initial;
  }
  .description_raw{
    margin-top:0.5rem;
    font-weight: 600;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
    margin-left: 50px;
  }
  .table_list tr td:first-child{
    padding-left:0;
    text-align: center;
  }
  .horizontal_dotted_line {
    display: flex;
    padding: 5px;
    font-weight: bold;
    height: 2rem;
  }
  .horizontal_dotted_line:after {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .horizontal_dotted_line:before {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .table_list{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history tr{
    background: #f1f1f1;
  }
  .table_list_des{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_des tr th{
    padding-block: 0.3rem;
  }
  .table_list_des tr td{
    padding-block: 0.3rem;
  }
  .row_print{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .row_print_center{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .row_print_des{
    margin-top: 0.5rem;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .page_print{
    font-family: 'Sarabun', sans-serif !important;
    width: 21cm;
    height: 29.7cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .page_print_landscape{
    font-family: 'Sarabun', sans-serif !important;
    width:  29.7cm;
    height: 21cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .header_top_report{
    display: none;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  body{
    font-family: 'Sarabun', sans-serif !important;
    font-size: 15px;
    background: white !important;
    line-height: 1.5;
  }

  .license_list{
    display: flex;
  }
  .license_item{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_custom{
    font-size: 2.2rem;
    font-weight: 300;
  }
  .header_sub_custom{
    font-size: 1.8rem;
    font-weight: 300;
  }
  .description_intro{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 0px !important;
    text-align: inherit;
    font-size: 1.2rem;
  }
  .row_data{
    background: none !important;
  }

  .row_data td{
    height: 4rem;
  }
  .list_check{
    line-height: 1.8rem;
  }
  .license_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }

}

</style>
