<template>
  <div class="license_list" >
    ลงชื่อ.......................................................... {{ type }}<br>
    ({{ name }})<br>
    {{ position }}<br>
  </div>
</template>

<script>
export default {
  name: "license",
  props: ["name","type","position"],
}
</script>

<style scoped>
@media print {
  .license_list {
    margin-top: 5rem;
    text-align: center;
  }
}
</style>
