<template>

      <v-row  class="mt-5 mb-5" align-content="center" justify="center"  >

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.3s'}" xl="4" lg="12" md="12" xs="12" cols="12" >
          <div class="card_container">
            <div align="center" class="profile_container" >
                <div class="img-container" >
                  <img id="image_preview"  class="img-profile" :src="img_src">
                    <label for="file-upload" class="upload_img">
                      <i class="fa-regular fa-image"></i>
                    </label>
                  <input
                      accept=".jpg, .jpeg"
                      @change="previewImage"
                      id="file-upload" class="file_upload_ip" type="file"/>
                </div>
              <div class="list_description" >
                <font><b>ชื่อ:</b> {{ first_name}} {{ last_name}} <br></font>
                <font v-if="users_.type == 'student' " ><b>รหัสนักศึกษา:</b> {{ users_.student_code }} <br></font>
                <font v-if="users_.type == 'student' || users_.type == 'lecturer' " ><b>สาขา:</b>{{ users_.branch }}<br></font>
                <font v-if="users_.type == 'teacher' || users_.type == 'manager' " ><b>ตำแหน่ง:</b>{{ users_.position == "teacher"  ?  "ครู" : "ผู้บริหารสถานศึกษา" }}<br></font>
                <font v-if="users_.type == 'teacher' || users_.type == 'manager' " ><b>โรงเรียน:</b>{{ users_.school }}<br></font>
              </div>

              <div class="edit_container" >
                <button @click="dialog = true" >
                  <i class="fa-regular fa-pen-to-square"></i>
                </button>
              </div>
              <div v-if="is_loading" class="lds-ripple"><div></div><div></div></div>
            </div>




            <i></i>
          </div>



        </v-col>

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.7s'}" v-if="users_.type == 'student' " xl="8" lg="12" md="12" xs="12" cols="12" >
          <div class="card_container">
            <font class="header_title" >ข้อมูลทั่วไป</font><hr class="horizontal_line" ><br>
            <div class="list_description" >
              <v-row>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศเอก:</b> {{ users_.relationwork.specific && users_.relationwork.specific.first_name }} {{ users_.relationwork.specific && users_.relationwork.specific.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศทั่วไป:</b> {{ users_.relationwork.general && users_.relationwork.general.first_name }} {{ users_.relationwork.general && users_.relationwork.general.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>โรงเรียนฝึกสอน:</b> {{ users_.relationwork.school && users_.relationwork.school.name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>สังกัด:</b> {{ users_.relationwork.school && users_.relationwork.school.affiliation }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 1:</b> {{ users_.relationwork.course_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 2:</b> {{ users_.relationwork.course_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ผู้บริหารสถานศึกษา:</b> {{ users_.relationwork.manager && users_.relationwork.manager.first_name }} {{ users_.relationwork.manager && users_.relationwork.manager.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ครูพี่เลี้ยง :</b> {{ users_.relationwork.teacher && users_.relationwork.teacher.first_name }} {{ users_.relationwork.teacher && users_.relationwork.teacher.last_name }} </font></v-col>
              </v-row>
            </div><br>
          </div>

          <v-row class="mt-2" >
            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศเอก</font><hr class="horizontal_line" ><br>
              <PeopleCard
                :data="users_.relationwork.specific"
                types="specific"
                :callback="()=> GotoAssessment('specific',users_.relationwork.specific)"
              />
              </div>
            </v-col>

            <v-col class="d-none" xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศทั่วไป</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.general"
                    types="general"
                    :callback="()=> GotoAssessment('general',users_.relationwork.general)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ผู้บริหารสถานศึกษา</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.manager"
                    types="manager"
                    :callback="()=> GotoAssessment('manager',users_.relationwork.manager)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ครูพี่เลี้ยง</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.teacher"
                    types="teacher"
                    :callback="()=> GotoAssessment('teacher',users_.relationwork.teacher)"
                />
              </div>
            </v-col>

          </v-row>

        </v-col>

        <v-row justify="center">

          <v-dialog  v-model="dialog" persistent >
            <v-card  class="mx-auto dialog_edit" outlined>
              <button @click="dialog = false" class="close_btn" >❌</button>
              <div class="detail_list">

                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >

                  <div v-if="users.type == 'student'" >

                    <v-row class="row_custom" >
                      <v-col class="col_custom" xl="8" lg="8" md="6" sm="12" cols="12" >
                        <v-text-field
                            v-model="course_1"
                            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                            label="รายวิชาที่สอน ภาคเรียนที่ 1"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col class="col_custom" xl="4" lg="4" md="6" sm="12" cols="12" >
                        <v-text-field
                            v-model="class_name_1"
                            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                            label="ระดับชั้น"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                    <v-row class="row_custom" >
                      <v-col class="col_custom" xl="8" lg="8" md="6" sm="12" cols="12" >
                        <v-text-field
                            v-model="course_2"
                            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                            label="รายวิชาที่สอน ภาคเรียนที่ 2"
                            required
                        ></v-text-field>
                      </v-col>
                      <v-col class="col_custom" xl="4" lg="4" md="6" sm="12" cols="12" >
                        <v-text-field
                            v-model="class_name_2"
                            :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                            label="ระดับชั้น"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>

                  </div>



                  <v-row class="row_custom" >
                    <v-col class="col_custom" xl="12" lg="12" md="12" sm="12" cols="12" >
                      <v-text-field
                          v-model="first_name"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="ชื่อ"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row class="row_custom" >
                    <v-col class="col_custom" xl="12" lg="12" md="12" sm="12" cols="12" >
                      <v-text-field
                          v-model="last_name"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="นามสกุล"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>



                  <v-row class="row_custom" >
                    <v-col class="col_custom" xl="12" lg="12" md="12" sm="12" cols="12" >
                      <v-text-field
                          v-model="email"
                          :rules="email_rules"
                          label="email"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row class="row_custom" >
                    <v-col class="col_custom" xl="12" lg="12" md="12" sm="12" cols="12" >
                      <v-text-field
                          v-model="phone"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="เบอรโทร"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>







                  <v-row>
                    <div class="btn_container" >
                      <v-btn
                          class="mr-2 update_btn"
                          @click="validate"
                      >
                        แก้ไข
                      </v-btn>

                      <v-btn
                          class="mr-2 reset_btn"
                          @click="reset"
                      >
                        ยกเลิก
                      </v-btn>
                    </div>

                  </v-row>



                </v-form>


              </div>
            </v-card>
          </v-dialog>


          <v-dialog  v-model="dialog_password" width="500" max-width="600" persistent >
            <v-card  class="mx-auto dialog_edit"  outlined>
              <div class="detail_list">

                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >

                  <v-row>
                    <v-col xl="8" lg="8" md="6" sm="12" cols="12" >
                      <v-text-field
                          v-model="course_1"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="รายวิชาที่สอน ภาคเรียนที่ 1"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="6" sm="12" cols="12" >
                      <v-text-field
                          v-model="class_name_1"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="ระดับชั้น"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col xl="8" lg="8" md="6" sm="12" cols="12" >
                      <v-text-field
                          v-model="course_1"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="รายวิชาที่สอน ภาคเรียนที่ 2"
                          required
                      ></v-text-field>
                    </v-col>
                    <v-col xl="4" lg="4" md="6" sm="12" cols="12" >
                      <v-text-field
                          v-model="class_name_2"
                          :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
                          label="ระดับชั้น"
                          required
                      ></v-text-field>
                    </v-col>
                  </v-row>


                  <v-row>
                    <div class="btn_container" >
                      <v-btn
                          class="mr-2 update_btn"
                          @click="validate"
                      >
                        บันทึก
                      </v-btn>

                      <v-btn
                          class="mr-2 reset_btn"
                          @click="reset"
                      >
                        ยกเลิก
                      </v-btn>
                    </div>

                  </v-row>



                </v-form>


              </div>
            </v-card>
          </v-dialog>


        </v-row>

      </v-row>



</template>
<script>
// @ is an alias to /src

import {baseUrlMain} from "../const/api";
import PeopleCard from "../components/PeopleCard";
import {getUser, UpdateProfile, UpdateUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";
import $ from "jquery";

export default {
  name: 'Home',
  components:{
    PeopleCard
  },
  data: () => ({
    is_student:true,
    users:JSON.parse(localStorage.getItem('user')),
    users_:JSON.parse(localStorage.getItem('user')),
    baseUrl:baseUrlMain,
    email_rules: [
      v => !!v || 'อีเมลต้องไม่เป็นค่าว่าง',
      v => /.+@.+\..+/.test(v) || 'รูปแบบอีเมลไม่ถูกต้อง',
    ],
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
    dialog:false,
    dialog_password:false,
    valid:true,
    valid_password:true,
    course_1:"",
    course_2:"",
    class_name_1:"",
    class_name_2:"",
    email:"",
    tel:"",
    phone:"",
    first_name:"",
    last_name:"",
    img_src:"",
    file:"",
    is_loading:false,
  }),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        type:this.users.type,
        id: this.users.id,
      }

      await getUser(params, (response) => {

        const {status, data} = ResponseData(response)

        switch (status) {
          case 200:

            console.log(data)
            this.users_ = data;
            this.users_.type = this.users.type;

            this.email = data.email;

            if(this.users.type == 'student'){
              this.course_1 = data.relationwork.course_1;
              this.course_2 = data.relationwork.course_2;
              this.class_name_1 = data.relationwork.class_name_1;
              this.class_name_2 = data.relationwork.class_name_2;
              this.tel = data.tel;

              this.email = data.email ? data.email : data.student_code+"@rmu.ac.th" ;

            }

            this.phone = data.phone;
            this.img_src = this.baseUrl+'/'+JSON.parse(this.users_.profile).path_thumb;
            this.first_name = data.first_name;
            this.last_name = data.last_name;
            this.is_loading = false;

            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    GotoAssessment(type,res){
      //console.log(res)
      this.$router.push({ path: "rate_view/"+type+"/"+res.id+"/"+this.users_.id });
    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.Update();
      }
    },
    reset () {
      this.$refs.form.reset()
    },
    validate_password () {
      console.log(this.file)
      if(this.$refs.form_password.validate()){
        this.Update();
      }
    },
    reset_password () {
      this.$refs.form_password.reset()
      $('#image_preview').attr('src', '');
    },
    async Update(){
      this.is_loading = true;
      this.dialog = false;
      let formData = new FormData();

      formData.append("id",this.users.id);
      formData.append("type_user",this.users.type);
      formData.append("first_name",this.first_name);
      formData.append("last_name",this.last_name);

      if(this.users.type == 'student'){
      formData.append("course_1",this.course_1);
      formData.append("course_2",this.course_2);
      formData.append("class_name_1",this.class_name_1);
      formData.append("class_name_2",this.class_name_2);

      }

      formData.append("tel",this.tel);
      formData.append("phone",this.phone);
      formData.append("email",this.email);



      await UpdateUser(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
              await this.GetData();
            });

            break;
          default:

            this.is_loading = false;

            break;
        }

      })

    },
    previewImage(event) {
      this.is_loading = true;
      console.log(event)
      this.file = event.target.files[0];
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          //this.img_src = e.target.result;
          this.UploadImage();
        };
        reader.readAsDataURL(file);
      }
    },
   async UploadImage(){

      let formData = new FormData();
      formData.append("id",this.users.id);
      formData.append("type",this.users.type);
      formData.append("file",this.file);

      await UpdateProfile(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
            console.log(data)
            this.GetData();
            break;
          default:

            break;
        }

      })


    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
<style>
.mx-auto dialog_edit{
  min-width: 400px;
}
.v-sheet.v-card{
  min-width: 400px;
  max-width: 800px;
}
</style>
