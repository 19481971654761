<template>
      <v-row class="mt-5" v-if="users_.length !== 0" align-content="center" justify="center"  >

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.3s'}" xl="4" lg="4" md="6" xs="12" cols="12" >
          <div class="card_container">
            <div align="center" class="profile_container" >
            <img  class="img-profile" :src="baseUrl+'/'+JSON.parse(users_.profile).path_thumb"><br>
              <div class="list_description" >
                <font><b>ชื่อ:</b> {{ users_.first_name}} {{ users_.last_name}} <br></font>
                <font  ><b>รหัสนักศึกษา:</b> {{ users_.student_code}} <br></font>
                <font  ><b>สาขา:</b>{{ users_.branch }}<br></font>
              </div>

            </div>

            <v-row class="pl-5 mt-4" >

            </v-row>



          </div>

          <div class="card_container mt-4">
          <font class="header_title" >ข้อมูลการฝึกปฏิบัติงาน</font><hr class="horizontal_line" ><br>
          <v-row class="pa-5" >
            <v-col v-for="result in users_.listlink" :key="result.id" xl="6" lg="6" md="6" xs="12" cols="12" >
              <a target="_blank" class="link_list" :href="result.url" > <v-icon>mdi-paperclip</v-icon> {{ result.title }}</a>
            </v-col>
          </v-row>
          </div>

        </v-col>

        <v-col v-wow="{ 'animation-name': 'fadeInUp','animation-duration': '1.7s'}"  xl="8" lg="8" md="6" xs="12" cols="12" >
          <div class="card_container">
            <font class="header_title" >ข้อมูลทั่วไป</font><hr class="horizontal_line" ><br>
            <div class="list_description" >
              <v-row>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศเอก:</b> {{ users_.relationwork.specific && users_.relationwork.specific.first_name }} {{ users_.relationwork.specific && users_.relationwork.specific.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>อาจารย์นิเทศทั่วไป:</b> {{ users_.relationwork.general && users_.relationwork.general.first_name }} {{ users_.relationwork.general && users_.relationwork.general.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>โรงเรียนฝึกสอน:</b> {{ users_.relationwork.school && users_.relationwork.school.name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>สังกัด:</b> {{ users_.relationwork.school && users_.relationwork.school.affiliation }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 1:</b> {{ users_.relationwork.course_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_1 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>รายวิชาที่สอน ภาคเรียนที่ 2:</b> {{ users_.relationwork.course_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ระดับชั้น:</b> {{ users_.relationwork.class_name_2 }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ผู้บริหารสถานศึกษา:</b> {{ users_.relationwork.manager && users_.relationwork.manager.first_name }} {{ users_.relationwork.manager && users_.relationwork.manager.last_name }} </font></v-col>
                <v-col xl="6" lg="6" md="6" xs="12" cols="12" class="col-description" ><font><b>ครูพี่เลี้ยง :</b> {{ users_.relationwork.teacher && users_.relationwork.teacher.first_name }} {{ users_.relationwork.teacher && users_.relationwork.teacher.last_name }} </font></v-col>
              </v-row>
            </div><br>
          </div>

          <v-row class="mt-2" >
            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศเอก</font><hr class="horizontal_line" ><br>
              <PeopleCard
                :data="users_.relationwork.specific"
                :student_id="users_.id"
                types="specific"
                :callback="()=> GotoAssessment('specific',users_.relationwork.specific)"
              />
              </div>
            </v-col>

            <v-col class="d-none" xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >อาจารย์นิเทศทั่วไป</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.general"
                    :student_id="users_.id"
                    types="general"
                    :callback="()=> GotoAssessment('general',users_.relationwork.general)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ผู้บริหารสถานศึกษา</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.manager"
                    :student_id="users_.id"
                    types="manager"
                    :callback="()=> GotoAssessment('manager',users_.relationwork.manager)"
                />
              </div>
            </v-col>

            <v-col xl="6" lg="6" md="6" xs="12" cols="12" >

              <div class="card_container">
                <font class="header_title" >ครูพี่เลี้ยง</font><hr class="horizontal_line" ><br>
                <PeopleCard
                    :data="users_.relationwork.teacher"
                    :student_id="users_.id"
                    types="teacher"
                    :callback="()=> GotoAssessment('teacher',users_.relationwork.teacher)"
                />
              </div>
            </v-col>

          </v-row>

        </v-col>




      </v-row>
</template>
<script>
// @ is an alias to /src

import {baseUrlMain} from "../const/api";
import PeopleCard from "../components/PeopleCard";
import {getUser} from "../lib/controller";
import {ResponseData} from "../lib/utility";

export default {
  name: 'Home',
  components:{
    PeopleCard
  },
  data: () => ({
    is_student:true,
    users:JSON.parse(localStorage.getItem('user')),
    users_:[],
    baseUrl:baseUrlMain,
    items: [
      {
        text: 'Home',
        disabled: false,
        href: '/',
      },
    ],
  }),
  methods:{
    async GetData() {

      this.is_loading = true;
      console.log(this)

      let params = {
        type:"student",
        id: this.$route.params.id,
      }

      await getUser(params, (response) => {

        const {status, data} = ResponseData(response)

        switch (status) {
          case 200:

            this.users_ = data;

            break;
          default:

            this.is_loading = false;
            break;
        }

      });
    },
    GotoAssessment(type,res){
      //console.log(res)
      this.$router.push({ path: "/rate_view/"+type+"/"+res.id+"/"+this.users_.id });
    },
  },
  async mounted() {
   await this.GetData();
  }
}
</script>
<style>
.title_custom{
  font-size: 1.5rem;
}
.link_list{
  text-decoration: none;
  font-size: 1rem;
  color: #085fb6;
}
.link_list i{
  color: #003773 !important;
}
</style>
