import { render, staticRenderFns } from "./plan_requirement.vue?vue&type=template&id=2dc25951&scoped=true&"
import script from "./plan_requirement.vue?vue&type=script&lang=js&"
export * from "./plan_requirement.vue?vue&type=script&lang=js&"
import style0 from "./plan_requirement.vue?vue&type=style&index=0&id=2dc25951&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2dc25951",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBreadcrumbs,VBreadcrumbsItem,VBtn,VCheckbox,VForm,VTextField,VTextarea})
