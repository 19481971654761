import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'


import AAR1 from '../views/AAR/AAR1'
import AAR2 from '../views/AAR/AAR2'
import AAR3 from '../views/AAR/AAR3'
import AAR_Print from '../views/AAR/AAR_Print'
import PLC from '../views/plc/plc'
import PLC2 from '../views/plc/plc2'
import PLC_PRINT from '../views/plc/plc_print'
import plan_1 from '../views/plan/plan_1'
import plan_history from '../views/plan/plan_history'
import plan_history_print from '../views/plan/Plan_Print_History'
import plan_requirement from '../views/plan/plan_requirement'
import plan_detail from '../views/plan/plan_detail'
import plan_print_detail from '../views/plan/Plan_Print_Detail'
import plan_print from '../views/plan/Plan_Print'
import introduction from '../views/portfolio/introduction'
import profile from '../views/portfolio/profile'
import profile_print from '../views/portfolio/Profile_Print'
import cer from '../views/portfolio/cer'
import course from '../views/portfolio/course'
import work from '../views/portfolio/work'
import workCommunity from '../views/portfolio/workCommunity'
import rate from '../views/rate'
import rate_view from '../views/rate_view'
import assessment from '../views/Assessment'
import information from '../views/list_link'
import Detail from '../views/Detail'
import Detail_view from '../views/DetailView'


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/assessment',
    name: 'Assessment',
    component: assessment
  },
  {
    path: '/aar_1',
    name: 'AAR1',
    component: AAR1
  },
  {
    path: '/aar_1_show/:id',
    name: 'AAR1_Show',
    component: AAR1
  },
  {
    path: '/aar_2',
    name: 'AAR2',
    component: AAR2
  },
  {
    path: '/aar_2_show/:id',
    name: 'AAR2_Show',
    component: AAR2
  },
  {
    path: '/aar_3',
    name: 'AAR3',
    component: AAR3
  },
  {
    path: '/aar_print',
    name: 'AAR_Print',
    component: AAR_Print
  },
  {
    path: '/plc',
    name: 'plc',
    component: PLC
  },
  {
    path: '/plc2',
    name: 'plc2',
    component: PLC2
  },
  {
    path: '/plan_1',
    name: 'plan_1',
    component: plan_1
  },
  {
    path: '/plan_print_detail',
    name: 'PlanPrintDetail',
    component: plan_print_detail
  },
  {
    path: '/plc_print',
    name: 'PLCPrint',
    component: PLC_PRINT
  },
  {
    path: '/plan_history',
    name: 'plan_history',
    component: plan_history
  },
  {
    path: '/plan_history_print',
    name: 'PlanHistoryPrint',
    component: plan_history_print
  },
  {
    path: '/plan_requirement',
    name: 'plan_requirement',
    component: plan_requirement
  },
  {
    path: '/plan_detail',
    name: 'plan_detail',
    component: plan_detail
  },
  {
    path: '/plan_print',
    name: 'PlanPrint',
    component: plan_print
  },
  {
    path: '/introduction',
    name: 'introduction',
    component: introduction
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile
  },
  {
    path: '/profile_print',
    name: 'ProfilePrint',
    component: profile_print
  },
  {
    path: '/cer',
    name: 'cer',
    component: cer
  },
  {
    path: '/course',
    name: 'course',
    component: course
  },
  {
    path: '/work',
    name: 'work',
    component: work
  },
  {
    path: '/work_community',
    name: 'workCommunity',
    component: workCommunity
  },
  {
    path: '/rate/:type/:student_id',
    name: 'rate',
    component: rate
  },
  {
    path: '/rate_view/:type/:id/:student_id',
    name: 'rate_view',
    component: rate_view
  },
  {
    path: '/rate_show/:type/:id/:student_id',
    name: 'rate_show',
    component: rate_view
  },
  {
    path: '/rate_show/:type/:id/:student_id',
    name: 'rate_show',
    component: rate_view
  },
  {
    path: '/information',
    name: 'information',
    component: information
  },
  {
    path: '/detail/:id',
    name: 'detail',
    component: Detail
  },
  {
    path: '/detail_view/:id',
    name: 'detail_view',
    component: Detail_view
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
