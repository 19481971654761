<template>
<div @click="check()"
     class="check_box"
     :class="class_name" >{{ is_check == true ? '✔' : ' ' }}
</div>
</template>

<script>
export default {
  props:["is_check","check","class_name"],
  name: "CheckBoxs"
}
</script>

<style scoped>
.red_color{
  color: red;

}
.green_color{
  color: green;

}
.blue_color{
  color: #0080ff;

}
.yellow_color{
  color: #d2d201;

}
.violet_color{
  color: #b100ff;

}
.orange_color{
  color: #ffa500;

}

.check_box{
  padding: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 2rem;
  transition: 0.5s;
}

</style>
