<template>
  <div>
    <div class="page_print">
      <div align="center" >
        แลกเปลี่ยนเรียนรู้ร่วมกันในรูปแบบชุมชนแห่งการเรียนรู้ (PLC) พัฒนา : Creative Discussion (แนวทางปฏิบัติและพัฒนาตนเอง)
      </div><br>

      <div class="row_print_des">
        1. หลักการและเหตุผล <br>
        <p v-html="principle_reason"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        2.วัตถุประสงค์ <br>
        <p v-html="objective"  class="description_raw">
        </p><br>
      </div>


    </div>

    <div class="page_print">

      <div class="row_print_des">
        3. วิธีการดำเนินงาน <br>
        <p v-html="method_operation"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp; 3.1 แนวทางการปฏิบัติกิจกรรมการสร้างชุมชนการเรียนรู้ทางวิชาชีพ (PLC) ดังนี้ <br>
        <p v-html="activity_guidelines"  class="description_raw">
        </p><br>
        &nbsp;&nbsp;&nbsp; 3.2 กระบวนการของ PLC<br>
        <p v-html="process_of_plc"  class="description_raw">
        </p><br>
        &nbsp;&nbsp;&nbsp; 3.3 บทบาทหน้าที่ของสมาชิกกลุ่มตามกระบวนการ PLC<br>
        <p v-html="role_of_plc"  class="description_raw">
        </p><br>
      </div>

    </div>

    <div class="page_print">

      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp; 3.4. วัน เวลา สถานที่ ในการดำเนินงาน<br>
        <p v-html="date_time_place"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.5 สรุปผลการดำเนินงาน<br>
        <p v-html="summary"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.1 ประเด็นด้านผู้เรียน<br>
        <p v-html="student_issues"  class="description_raw">
        </p><br>


      </div>

    </div>


    <div class="page_print">
      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.2 ประเด็นด้านกิจกรรม<br>
        <p v-html="activity_issues"  class="description_raw">
        </p><br>
      </div>

      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.3 ประเด็นด้านครู<br>
        <p v-html="teacher_issues"  class="description_raw">
        </p><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.4 ประเด็นสื่อการสอน<br>
        <p v-html="teaching_media_issues"  class="description_raw">
        </p><br>
      </div>

    </div>

    <div class="page_print">
      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.5.5 ประเด็นด้านบรรยากาศ<br>
        <p v-html="environment_issues"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.6 อภิปรายผลการดำเนินงาน<br>
        <p v-html="performance_discussion"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.1 ผลลัพธ์ที่เกิดจากกระบวนการ ดังนี้<br>
        <p v-html="result_issues"  class="description_raw">
        </p><br>

      </div>
    </div>

    <div class="page_print">
      <div class="row_print_des">

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.2 ผลลัพธ์ที่เกิดกับผู้เรียน / ครู / สมาชิกที่เข้าร่วมเครือข่าย PLC<br>
        <p v-html="result_student_teacher_plc"  class="description_raw">
        </p><br>


        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.3 คุณค่าที่เกิดต่อวงการศึกษา<br>
        <p v-html="result_student_education"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; 3.6.4 คุณค่าที่เกิดต่อวงการศึกษา<br>
        <p v-html="value_in_education"  class="description_raw">
        </p><br>

      </div>
    </div>

    <div class="page_print">
      <div class="row_print_des">
        &nbsp;&nbsp;&nbsp; 3.7 ผลที่เกิดจากการดำเนินงาน<br>
        <p v-html="result_operation"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.8 ร่องรอย/หลักฐาน<br>
        <p v-html="trace_evidence"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.9 บทเรียนที่ได้จากการดำเนินงาน<br>
        <p v-html="learned_Implementation"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.10 สิ่งที่จะดำเนินการต่อไป<br>
        <p v-html="what_to_do_next"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.11 ปัญหา /อุปสรรค<br>
        <p v-html="problems_obstacles"  class="description_raw">
        </p><br>

        &nbsp;&nbsp;&nbsp; 3.12 ข้อเสนอแนะ<br>
        <p v-html="suggestion"  class="description_raw">
        </p><br>

      </div>

    </div>

    <div class="page_print">
      <div align="center" >
        แผนปฏิบัติการชุมชนแห่งการเรียนรู้ทางวิชาชีพ (PLC Action Plan)<br>
        แผนการดำเนินงาน ภาคเรียนที่ 1 ปีการศึกษา 2566
      </div><br>

      <div class="row_print">
        1. ชื่อ-สกุล Model Teacher
        <div style="width: 463px;text-align: center" class="horizontal_dotted_line">{{ name }}</div>
        เจ้าของแผน
      </div>
      <div class="row_print">
        2. โรงเรียน
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">{{ school }}</div>
        สังกัด<div style="width: 450px;text-align: center" class="horizontal_dotted_line">{{ affiliation }}</div>
      </div>
      <div class="row_print">
       จังหวัด
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">{{ province }}</div>
      </div>
      <div class="row_print">
        3. รายวิชาที่ดำเนินการ
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">{{ course }}</div>
        กลุ่มสาระการเรียนรู้<div style="width: 282px;text-align: center" class="horizontal_dotted_line">{{ department }}</div>
      </div>
      <div class="row_print">
        4. นักเรียนชั้น / ห้อง
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">{{ class_room_name }}</div>
        นักเรียนชาย<div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ student_man_amount }}</div>คน
      </div>
      <div class="row_print" style="margin-top: 0" >
        นักเรียนหญิง<div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ student_woman_amount }}</div>คน
        รวม<div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ (student_woman_amount+student_man_amount) }}</div>คน
      </div>
      5. สมาชิกทีม PLT ประเภทเพื่อนครู (Buddy Teachers :BT)
      <div class="row_print">
       &nbsp;&nbsp;&nbsp;&nbsp; 5.1 เพื่อนครู
        <div style="width: 160px;text-align: center" class="horizontal_dotted_line">{{ friend_teacher }} </div>
        วิชาเอก<div style="width: 125px;text-align: center" class="horizontal_dotted_line">{{ friend_department }}</div>
        อีเมล<div style="width: 246px;text-align: center" class="horizontal_dotted_line">{{ friend_email }}</div>
      </div>

      6. สมาชิกทีม PLT ประเภทผู้บริหาร (School Administratos :SA)
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.1 ผู้อำนวยการโรงเรียน
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">{{ school_director }}</div>
        วิทยฐานะ<div style="width: 231px;text-align: center" class="horizontal_dotted_line">{{ director_academic }}</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">{{ director_department }}</div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.2 รองผู้อำนวยการโรงเรียน
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">{{ school_deputy_director }}</div>
        วิทยฐานะ<div style="width: 207px;text-align: center" class="horizontal_dotted_line">{{ deputy_director_academic }}</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">{{ deputy_director_department }}</div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 6.3 หัวหน้ากลุ่มสาระ
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">{{ school_group_head }}</div>
        วิทยฐานะ<div style="width: 207px;text-align: center" class="horizontal_dotted_line">{{ group_head_academic }}</div>
      </div>
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp;วิชาเอก<div style="width: 246px;text-align: center" class="horizontal_dotted_line">{{ group_head_department }}</div>
      </div>

      7. สมาชิกทีม PLT ประเภทผู้ทรงคุณวุฒิทางการศึกษา (Seniore Educator :SE)
      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 7.1
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line">{{ qualified_person }}</div>
        วิทยฐานะ/ตำแหน่งทางวิชาการ<div style="width: 231px;text-align: center" class="horizontal_dotted_line">{{ qualified_person_academic }}</div>
      </div>

      <div class="row_print" style="margin-top: 0" >
        &nbsp;&nbsp;&nbsp;&nbsp; 7.2
        <div style="width: 240px;text-align: center" class="horizontal_dotted_line"> </div>
        วิทยฐานะ/ตำแหน่งทางวิชาการ<div style="width: 231px;text-align: center" class="horizontal_dotted_line"></div>
      </div>

    </div>
  </div>
</template>

<script>
import {} from "../../lib/controller";
import {ResponseData} from "../../lib/utility";
import {getData} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'

export default {
  name: "PlcPrint",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    class_detail:"",
    people_amount:0,

    principle_reason:"",
    objective:"",
    method_operation:"",
    activity_guidelines:"",
    process_of_plc:"",
    role_of_plc:"",
    date_time_place:"",
    summary:"",
    student_issues:"",
    activity_issues:"",
    teacher_issues:"",
    teaching_media_issues:"",
    environment_issues:"",
    performance_discussion:"",
    result_issues:"",
    result_student_teacher_plc:"",
    result_student_education:"",
    value_in_education:"",
    result_operation:"",
    trace_evidence:"",
    learned_Implementation:"",
    what_to_do_next:"",
    problems_obstacles:"",
    suggestion:"",



    name:"",
    position:"",
    school:"",
    amphur:"",
    province:"",
    affiliation:"",
    course_select:"",

    course:"",

    department_select:"",
    class_room_name:"",
    student_amount:"",
    student_man_amount:"",
    student_woman_amount:"",

    friend_teacher:"",
    friend_department:"",
    friend_email:"",

    school_director:"",
    director_academic:"",
    director_department:"",

    school_deputy_director:"",
    deputy_director_academic:"",
    deputy_director_department:"",

    school_group_head:"",
    group_head_academic:"",
    group_head_department:"",
    qualified_person:"",
    qualified_person_academic:"",


    time: null,
    menu2: false,
    modal2: false,

    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
    this.GetDepartment()
    this.GetMachines()
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    validate() {
      console.log(this.file)
      if (this.$refs.form.validate()) {
        this.UserRegister();
      }
    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },

    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)



        switch (status){
          case 200:


            this.principle_reason = data.plc.principle_reason.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.objective = data.plc.objective.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.method_operation = data.plc.method_operation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.activity_guidelines = data.plc.activity_guidelines.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.process_of_plc = data.plc.process_of_plc.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.role_of_plc = data.plc.role_of_plc.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.date_time_place = data.plc.date_time_place.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.summary = data.plc.summary.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.student_issues = data.plc.student_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.activity_issues = data.plc.activity_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.teacher_issues = data.plc.teacher_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.teaching_media_issues = data.plc.teaching_media_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.environment_issues = data.plc.environment_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.performance_discussion = data.plc.performance_discussion.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.result_issues = data.plc.result_issues.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.result_student_teacher_plc = data.plc.result_student_teacher_plc.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.result_student_education = data.plc.result_student_education.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.value_in_education = data.plc.value_in_education.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.result_operation = data.plc.result_operation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.trace_evidence = data.plc.trace_evidence.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.learned_Implementation = data.plc.learned_Implementation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.what_to_do_next = data.plc.what_to_do_next.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.problems_obstacles = data.plc.problems_obstacles.replace(/(?:\r\n|\r|\n)/g, '<br />');
            this.suggestion = data.plc.suggestion.replace(/(?:\r\n|\r|\n)/g, '<br />');




            this.name = data.plc2.name;
            this.position = data.plc2.position;
            this.school = data.plc2.school;
            this.affiliation = data.plc2.affiliation;
            this.province = data.plc2.province;
            this.course = data.plc2.course;
            this.department = data.plc2.department;
            this.class_room_name = data.plc2.class_room_name;
            this.student_man_amount = parseInt(data.plc2.student_man_amount);
            this.student_woman_amount = parseInt(data.plc2.student_woman_amount);
            this.friend_teacher = data.plc2.friend_teacher;
            this.friend_department = data.plc2.friend_department;
            this.friend_email = data.plc2.friend_email;
            this.school_director = data.plc2.school_director;
            this.director_academic = data.plc2.director_academic;
            this.director_department = data.plc2.director_department;
            this.school_deputy_director = data.plc2.school_deputy_director;
            this.deputy_director_academic = data.plc2.deputy_director_academic;
            this.deputy_director_department = data.plc2.deputy_director_department;
            this.school_group_head = data.plc2.school_group_head;
            this.group_head_academic = data.plc2.group_head_academic;
            this.group_head_department = data.plc2.group_head_department;
            this.qualified_person = data.plc2.qualified_person;
            this.qualified_person_academic = data.plc2.qualified_person_academic;


            break;
          default:
            this.is_loading = false;
            break;
        }

      });
    },
  },
  async mounted() {
   await this.GetData()
    //print()
  }
}


</script>

<style scoped>
@media screen{
  .page_print{
    /*display: none;*/
  }

  .table_list tr td{
    padding-left: 10px;
    font-weight: 600;
  }
  .description{
    margin-top:0.5rem;
    font-weight: 600;
    text-indent: 50px;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
  }
  .description_question{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 16px;
    height: 101px;
    text-align: initial;
  }
  .description_raw{
    margin-top:0.5rem;
    font-weight: 600;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
    margin-left: 50px;
  }
  .table_list tr td:first-child{
    padding-left:0;
    text-align: center;
  }
  .horizontal_dotted_line {
    display: flex;
    padding: 5px;
    font-weight: bold;
    height: 2rem;
  }
  .horizontal_dotted_line:after {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .horizontal_dotted_line:before {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .table_list{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history tr{
    background: #f1f1f1;
  }
  .table_list_des{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_des tr th{
    padding-block: 0.3rem;
  }
  .table_list_des tr td{
    padding-block: 0.3rem;
  }
  .row_print{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .row_print_center{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .row_print_des{
    margin-top: 0.5rem;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .page_print{
    font-family: 'Sarabun', sans-serif !important;
    width: 21cm;
    height: 29.7cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .page_print_landscape{
    font-family: 'Sarabun', sans-serif !important;
    width:  29.7cm;
    height: 21cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .header_top_report{
    display: none;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  body{
    font-family: 'Sarabun', sans-serif !important;
    font-size: 15px;
    background: white !important;
    line-height: 1.5;
  }

  .license_list{
    display: flex;
  }
  .license_item{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_custom{
    font-size: 2.2rem;
    font-weight: 300;
  }
  .header_sub_custom{
    font-size: 1.8rem;
    font-weight: 300;
  }
  .description_intro{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 0px !important;
    text-align: inherit;
    font-size: 1.2rem;
  }
  .row_data{
    background: none !important;
  }

  .row_data td{
    height: 4rem;
  }
  .list_check{
    line-height: 1.8rem;
  }
  .license_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }

}
</style>
