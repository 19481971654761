<template>
<div @click="check()"
     class="check_box"
     :class="class_name" >{{ is_check == true ? '✔' : ' ' }}
</div>
</template>

<script>
export default {
  props:["is_check","check","class_name"],
  name: "CheckBoxs"
}
</script>

<style scoped>
.red_color{
  color: red;
  border: 1px solid rgba(255, 0, 0, 0.71) !important;
}
.green_color{
  color: green;
  border: 1px solid rgba(0, 128, 0, 0.34) !important;
}
.blue_color{
  color: #0080ff;
  border: 1px solid rgba(0, 128, 255, 0.5) !important;
}
.yellow_color{
  color: #d2d201;
  border: 1px solid rgba(210, 210, 1, 0.74) !important;
}
.violet_color{
  color: #b100ff;
  border: 1px solid rgba(177, 0, 255, 0.43) !important;
}
.orange_color{
  color: #ffa500;
  border: 1px solid rgba(255, 165, 0, 0.55) !important;
}

.check_box{
  padding: 0.2rem;
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.38);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 2rem;
  cursor: pointer;
  transition: 0.5s;
}
.check_box:hover{
  border: 3px solid rgb(255 0 0);
  transition: 0.5s;
}
.check_box:active{
  border: 3px solid rgb(255 0 0);
  transition: 0.5s;
}
</style>
