<template>
  <div>
    <div class="page_print">
      <div align="center" >
        แบบรายงานการประเมินผลสะท้อนกลับ (AAR)
      </div><br>
      1) การสะท้อนการเรียนรู้ วงรอบที่ 1 การสะท้อนแผนการจัดการเรียนรู้ก่อนใช้สอน วงรอบที่ 1 (นักศึกษา อาจารย์ผู้สอน
      อาจารย์นิเทศ)<br><br>
      <div class="row_print">
        ชื่อนักศึกษา
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.student.sex === 'male' ? 'นาย':'นางสาว' }}{{ this.student.first_name }} {{ this.student.last_name }}</div>
        โรงเรียน
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">โรงเรียน{{ this.student.relationwork.school ? this.student.relationwork.school.name : "-" }}</div>
      </div>
      <div class="row_print">
        อำเภอ
        <div style="width: 300px;text-align: center" class="horizontal_dotted_line">{{ this.student.relationwork.school ? this.student.relationwork.school.amphurename : "-" }}</div>
        จังหวัด
        <div style="width:314px;text-align: center" class="horizontal_dotted_line">{{ this.student.relationwork.school ? this.student.relationwork.school.provincename : "-" }}</div>
      </div>
      <div class="row_print">
        วันที่สะท้อนการจัดกิจกรรมการเรียนรู้ก่อนเปิดชั้นเรียน
        <div style="width: 364px;text-align: center" class="horizontal_dotted_line">{{ dateShow() }} เวลา 09.00 น.</div>
      </div>
      <div class="row_print">
        นักเรียนชั้น
        <div style="width: 210px;text-align: center" class="horizontal_dotted_line">{{ this.class_detail }}</div>
        จำนวนนักเรียน
        <div style="width: 295px;text-align: center" class="horizontal_dotted_line">{{ this.student_amount }}</div>
        คน
      </div>
      <div class="row_print">
        รายวิชา/กลุ่มสาระการเรียนรู้
        <div style="width: 522px;text-align: center" class="horizontal_dotted_line">{{ this.departments }}</div>
      </div>
      <div class="row_print">
        จำนวนผู้เข้าร่วมสะท้อนแผน จำนวน
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ this.people_amount }}</div>
        คน ได้แก่
      </div>
      <div class="row_print">
        <table border="1" class="table_list">
          <tr>
            <th style="width:10% ">ลำดับที่</th>
            <th style="width:40%">ชื่อ-สกุล</th>
            <th style="width:30%">ผู้ร่วม AAR</th>
            <th style="width:20%">ลายมือชื่อ</th>
          </tr>
          <tr v-for="index in parseInt(this.people_amount) " :key="index" >
            <td>{{ index }}</td>
            <td>{{ name_visitor[index-1] }}</td>
            <td>{{ type_visitor[index-1] }}</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>
    <div class="page_print">
      <div class="row_print_des">
        1. องค์ประกอบของแผนจัดการเรียนรู้<br>
        <p class="description">
          {{ plan_detail }}
        </p>
      </div>

      <div class="row_print_des">
        2.การออกแบบจุดประสงค์การเรียนรู้ที่สอดคล้องกับกิจกรรมการเรียนรู้<br>
        <p class="description">
          {{ plan_design }}
        </p>
      </div>

      <div class="row_print_des">
        3. กิจกรรมการเรียนรู้ที่ครูผู้สอนออกแบบมีความสอดคล้องตามตัวชี้วัด<br>
        <p class="description">
          {{ activity_plan }}
        </p>
      </div>

      <div class="row_print_des">
        4. ชิ้นงาน/ภาระงาน/การวัดประเมินผล สอดคล้องกับกิจกรรมการเรียนรู้<br>
        <p class="description">
          {{ work_sheet }}
        </p>
      </div>

      <div class="row_print_des">
        5. วิธีการแก้ไขปัญหาที่มีการวางแผนสู่การจัดกิจกรรมการเรียนรู้มีความเหมาะสม<br>
        <p class="description">
          {{ plan_problem_fix }}
        </p>
      </div>
    </div>
    <div class="page_print">
      <div class="row_print">
        สรุปเวลา
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">{{ total_hour }}</div>
        ชั่วโมง
      </div>
      <div class="license_container" >
        <div  v-for="index in parseInt(this.people_amount) " :key="index" class="license_list">
          <div class="license_item" >
            <License
                :name="name_visitor[index-1]"
                type="ผู้รับรอง"
                :position="type_visitor[index-1]"
            />
          </div>
        </div>
      </div>

    </div>

    <div class="page_print">
      <div align="center" >
        แบบรายงานการประเมินผลสะท้อนกลับ (AAR)
      </div><br>
      2) การสะท้อนการเรียนรู้ วงรอบที่ 2 การสะท้อนการจัดการเรียนรู้วงรอบที่ 2 (ครูพี่เลี้ยงผู้บริหารสถานศึกษาและอาจารย์นิเทศ)<br><br>
      <div class="row_print">
        ชื่อนักศึกษา
        <div style="width: 318px;text-align: center" class="horizontal_dotted_line">{{ this.student.sex === 'male' ? 'นาย':'นางสาว' }}{{ this.student.first_name }} {{ this.student.last_name }}</div>
        โรงเรียน
        <div style="width: 270px;text-align: center" class="horizontal_dotted_line">{{ this.student.relationwork.school ? this.student.relationwork.school.name : "-" }}</div>
      </div>
      <div class="row_print">
        อำเภอ
        <div style="width: 300px;text-align: center" class="horizontal_dotted_line">{{ this.student.relationwork.school ? this.student.relationwork.school.amphurename : "-" }}</div>
        จังหวัด
        <div style="width:314px;text-align: center" class="horizontal_dotted_line">{{ this.student.relationwork.school ? this.student.relationwork.school.provincename : "-" }}</div>
      </div>
      <div class="row_print">
        วันที่สะท้อนการจัดกิจกรรมการเรียนรู้ก่อนเปิดชั้นเรียน
        <div style="width: 364px;text-align: center" class="horizontal_dotted_line">{{ dateShow(this.date_1) }} เวลา {{ timeShow(this.time_1) }} น.</div>
      </div>
      <div class="row_print">
        นักเรียนชั้น
        <div style="width: 210px;text-align: center" class="horizontal_dotted_line">{{ class_name_2 }}</div>
        จำนวนนักเรียน
        <div style="width: 295px;text-align: center" class="horizontal_dotted_line">{{ amount_student_2 }}</div>
        คน
      </div>
      <div class="row_print">
        รายวิชา/กลุ่มสาระการเรียนรู้
        <div style="width: 522px;text-align: center" class="horizontal_dotted_line">{{ departments_2 }}</div>
      </div>
      <div class="row_print">
        จำนวนผู้เข้าร่วมสะท้อนแผน จำนวน
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">3</div>
        คน ได้แก่
      </div>
      <div class="row_print">
        <table border="1" class="table_list">
          <tr>
            <th style="width:10% ">ลำดับที่</th>
            <th style="width:40%">ชื่อ-สกุล</th>
            <th style="width:30%">ผู้ร่วม AAR</th>
            <th style="width:20%">ลายมือชื่อ</th>
          </tr>
          <tr v-for="index in parseInt(this.people_amount_2) " :key="index" >
            <td>{{ index }}</td>
            <td>{{ name_visitor_2[index-1] }}</td>
            <td>{{ type_visitor_2[index-1] }}</td>
            <td></td>
          </tr>
        </table>
      </div>


    </div>

    <div class="page_print">
      <div class="row_print_des">
        1. สิ่งที่ครูผู้สอนทำได้ดีและควรรักษาไว้ให้มีต่อไป <br>
        <p class="description">
          {{ well_done }}
        </p>
      </div>

      <div class="row_print_des">
        2.สิ่งที่เป็นปัญหาและอุปสรรคที่ทำให้การจัดกิจกรรมการเรียนรู้ในครั้งนี้ไม่เป็นไปตามเป้าหมาย<br>
        <p class="description">
          {{ not_on_target }}
        </p>
      </div>

      <div class="row_print_des">
        3. สิ่งที่ครูต้องปรับให้ดีขึ้นเพื่อพัฒนาการเรียนรู้ของนักเรียนมีประเด็นโดบ้างและจะทำอย่างไร<br>
        <p class="description">
          {{ improve }}
        </p>
      </div>

      <div class="row_print_des">
        4. นักเรียนบรรลุวัตถุประสงค์การเรียนรู้ในครั้งนี้จำนวนกี่คน<br>
        <p class="description">
          {{ student_achieve }}
        </p>
      </div>

      <div class="row_print_des">
        5. วิธีการแก้ปัญหาที่นำมาใช้ในการจัดกิจกรรมการเรียนรู้เกิดผลอย่างไร<br>
        <p class="description">
          {{ howto_fix }}
        </p>
      </div>
    </div>

    <div class="page_print">
      <div class="row_print">
        สรุปเวลา
        <div style="width: 100px;text-align: center" class="horizontal_dotted_line">  {{ total_hour_2 }}</div>
        ชั่วโมง
      </div>
      <div class="license_container" >
        <div  v-for="index in parseInt(this.people_amount_2) " :key="index" class="license_list">
          <div class="license_item" >
            <License
                :name="name_visitor_2[index-1]"
                type="ผู้รับรอง"
                :position="type_visitor_2[index-1]"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="page_print" >
      3) การทบทวนหลังการปฏิบัติงาน (After Action Review : AAR)<br><br>
      <div align="center" >
        แบบบันทึกการทบทวนหลังการปฏิบัติงาน (After Action Review : AAR)<br>
        การทบทวนหลังการปฏิบัติการสอนในสถานศึกษา<br>
        หน่วยงาน <b>โรงเรียนนครขอนแก่น</b>
      </div><br>
      กระบวนการจัดการความรู้มี 7 กิจกรรม ดังนี้ <br><br>

      <p class="description_question">
        1) การบ่งชี้ความรู้ คือ <b>{{ indication_of_knowledge }}</b>
      </p>
      <p class="description_question">
        2) การสร้างและแสวงหา ความรู้ คือ <b>{{ creating_knowledge }}</b>
      </p>
      <p class="description_question">
        3) การจัดความรู้ให้เป็นระบบ คือ <b>{{ knowledge_into_system }}</b>
      </p>
      <p class="description_question">
        4) การประมวลและกลั่นกรอง ความรู้ คือ  <b>{{ filtering_knowledge }}</b>
      </p>
      <p class="description_question">
        5) การเข้าถึงความรู้ คือ   <b>{{ access_knowledge }}</b>
      </p>
      <p class="description_question">
        6) การแบ่งปันแลกเปลี่ยนความรู้ คือ   <b>{{ knowledge_sharing }}</b>
      </p>
      <p class="description_question">
        7) การเรียนรู้ คือ    <b> {{ learning }}</b>
      </p>

    </div>
    <div class="page_print" >
      <table border="1" class="table_list_des" >
        <tr>
          <th style="width: 20%;text-align: left;padding-left: 10px;" >หน่วยงาน</th>
          <th style="width: 80%;text-align: left;padding-left: 10px;" >โรงเรียน{{ this.student.relationwork.school ? this.student.relationwork.school.name : "-" }}</th>
        </tr>
        <tr>
          <th style="width: 20%;text-align: left;padding-left: 10px;" >ชื่องาน</th>
          <th style="width: 80%;text-align: left;padding-left: 10px;" >{{ this.work_name }}</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >เป้าหมายของงาน</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ goal_of_event }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ผลการปฏิบัติ/ผลลัพธ์ที่เกิดขึ้นจริง (โดยสรุป)</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ summary }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >งาน/ขั้นตอนที่ทำได้ดี </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ good_job_step }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >งาน/ขั้นตอนที่ทำได้ไม่ดี </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ poor_job_step }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >อุปสรรค/ข้อจำกัด/ข้อขัดข้อง ที่พบในระหว่างการปฏิบัติงาน </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ obstacles_limitations }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ประเด็นที่ได้เรียนรู้</th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ learned_issues }}
          </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >ข้อปฏิบัติในการทำงานครั้งต่อไป </th>
        </tr>
        <tr>
          <th colspan="2" style="text-align: left;padding-left: 10px;" >
            {{ guidelines_next_job }}
          </th>
        </tr>
      </table>
    </div>

  </div>
</template>

<script>
import {getData} from "../../lib/controller";
import {ResponseData} from "../../lib/utility";
import License from "@/components/License";
import {baseUrl} from "../../const/api";

import $ from 'jquery'
import moment from "moment";

export default {
  name: "AARPrint",
  components: {
    License
  },
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    users:JSON.parse(localStorage.getItem('user')),
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    student: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',



    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",
    plan_design: "",
    total_hour: "",
    departments: "",
    name_visitor: "",
    type_visitor: "",

    date_1: "",
    time_1: "",
    date_2: "",
    time_2: "",
    class_name_2: "",
    amount_student_2: "",
    people_amount_2:0,
    department_select: "",
    well_done: "",
    not_on_target: "",
    improve: "",
    student_achieve: "",
    howto_fix: "",
    total_hour_2: "",
    departments_2: "",
    name_visitor_2: "",
    type_visitor_2: "",

    indication_of_knowledge:"",
    creating_knowledge:"",
    knowledge_into_system:"",
    filtering_knowledge:"",
    access_knowledge:"",
    knowledge_sharing:"",
    learning:"",
    work_name:"",
    goal_of_event:"",
    summary:"",
    good_job_step:"",
    poor_job_step:"",
    obstacles_limitations:"",
    learned_issues:"",
    guidelines_next_job:"",


    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    dateShow(date){
      moment.locale('th');
      return moment(date).format('Do MMMM YYYY');
    },
    timeShow(date){
      moment.locale('th');
      return moment(date).format('h:mm:ss a');
    },
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    reset() {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const name_visitor = [];
        const type_visitor = [];

        const name_visitor_2 = [];
        const type_visitor_2 = [];

        switch (status){
          case 200:
            console.log(data.aar_1)

            this.is_loading = false;
            this.date_1 = data.aar_1.date_activity;
            this.time_1 = data.aar_1.time_activity;
            this.class_detail = data.aar_1.class_name;
            this.student_amount = data.aar_1.amount_student;
            this.department_select = data.aar_1.department;
            this.plan_detail = data.aar_1.plan_detail;
            this.plan_design = data.aar_1.plan_design;
            this.activity_plan = data.aar_1.activity_plan;
            this.work_sheet = data.aar_1.work_sheet;
            this.plan_problem_fix = data.aar_1.plan_problem_fix;
            this.total_hour = data.aar_1.total_hour;
            this.departments = data.aar_1.departments;
            this.student = data.data;


            this.date_2 = data.aar_2.date_activity;
            this.time_2 = data.aar_2.time_activity;
            this.class_name_2 = data.aar_2.class_name;
            this.amount_student_2 = data.aar_2.amount_student;
            this.department_select = data.aar_2.department;
            this.well_done = data.aar_2.well_done;
            this.not_on_target = data.aar_2.not_on_target;
            this.improve = data.aar_2.improve;
            this.student_achieve = data.aar_2.student_achieve;
            this.howto_fix = data.aar_2.howto_fix;
            this.total_hour_2 = data.aar_2.total_hour;
            this.departments_2 = data.aar_2.departments;


            this.work_name = data.aar_3.work_name;
            this.indication_of_knowledge = data.aar_3.indication_of_knowledge;
            this.creating_knowledge = data.aar_3.creating_knowledge;
            this.knowledge_into_system = data.aar_3.knowledge_into_system;
            this.filtering_knowledge = data.aar_3.filtering_knowledge;
            this.access_knowledge = data.aar_3.access_knowledge;
            this.knowledge_sharing = data.aar_3.knowledge_sharing;
            this.goal_of_event = data.aar_3.goal_of_event;
            this.summary = data.aar_3.summary;
            this.good_job_step = data.aar_3.good_job_step;
            this.poor_job_step = data.aar_3.poor_job_step;
            this.obstacles_limitations = data.aar_3.obstacles_limitations;
            this.learned_issues = data.aar_3.learned_issues;
            this.guidelines_next_job = data.aar_3.guidelines_next_job;
            this.learning = data.aar_3.learning;




            data.visitor_aar1.forEach((result)=>{
              console.log(result)
              name_visitor.push(result.name)
              type_visitor.push(result.type)
            })

            this.people_amount = name_visitor.length;
            this.name_visitor = name_visitor;
            this.type_visitor = type_visitor;


            data.visitor_aar2.forEach((result)=>{
              console.log(result)
              name_visitor_2.push(result.name)
              type_visitor_2.push(result.type)
            })

            this.people_amount_2 = name_visitor_2.length;
            this.name_visitor_2 = name_visitor_2;
            this.type_visitor_2 = type_visitor_2;


            break;
          default:
            this.is_loading = false;
            break;
        }

      });
    },
  },
  async mounted() {
    await this.GetData()
    //print()
  }
}


</script>

<style scoped>

@media screen{
  .page_print{
    /*display: none;*/
  }

  .table_list tr td{
    padding-left: 10px;
    font-weight: 600;
  }
  .description{
    margin-top:0.5rem;
    font-weight: 600;
    text-indent: 50px;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
  }
  .description_question{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 16px;
    height: 101px;
    text-align: initial;
  }
  .description_raw{
    margin-top:0.5rem;
    font-weight: 600;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
    margin-left: 50px;
  }
  .table_list tr td:first-child{
    padding-left:0;
    text-align: center;
  }
  .horizontal_dotted_line {
    display: flex;
    padding: 5px;
    font-weight: bold;
    height: 2rem;
  }
  .horizontal_dotted_line:after {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .horizontal_dotted_line:before {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .table_list{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history tr{
    background: #f1f1f1;
  }
  .table_list_des{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_des tr th{
    padding-block: 0.3rem;
  }
  .table_list_des tr td{
    padding-block: 0.3rem;
  }
  .row_print{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .row_print_center{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .row_print_des{
    margin-top: 0.5rem;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .page_print{
    font-family: 'Sarabun', sans-serif !important;
    width: 21cm;
    height: 29.7cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .page_print_landscape{
    font-family: 'Sarabun', sans-serif !important;
    width:  29.7cm;
    height: 21cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .header_top_report{
    display: none;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  body{
    font-family: 'Sarabun', sans-serif !important;
    font-size: 15px;
    background: white !important;
    line-height: 1.5;
  }

  .license_list{
    display: flex;
  }
  .license_item{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_custom{
    font-size: 2.2rem;
    font-weight: 300;
  }
  .header_sub_custom{
    font-size: 1.8rem;
    font-weight: 300;
  }
  .description_intro{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 0px !important;
    text-align: inherit;
    font-size: 1.2rem;
  }
  .row_data{
    background: none !important;
  }

  .row_data td{
    height: 4rem;
  }
  .list_check{
    line-height: 1.8rem;
  }
  .license_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }

}


</style>
