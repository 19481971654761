import { render, staticRenderFns } from "./rate_view.vue?vue&type=template&id=2598a7c0&scoped=true&"
import script from "./rate_view.vue?vue&type=script&lang=js&"
export * from "./rate_view.vue?vue&type=script&lang=js&"
import style0 from "./rate_view.vue?vue&type=style&index=0&id=2598a7c0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2598a7c0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBreadcrumbs,VCheckbox,VCol,VForm,VRow})
