<template>
  <div>



    <div v-for="(result,index) in resultData" :key="index"  class="page_print_landscape">
      <div align="left" >
        ส่วนที่ 3 รายละเอียดแผนพัฒนาตนเองรายบุคคล
      </div><br>
      <table border="1" class="table_list_history" >
        <tr>
          <th rowspan="2" style="width: 5%" >ที่</th>
          <th rowspan="2" style="width: 15%" >สมรรถนะ ที่จะพัฒนา ***</th>
          <th rowspan="2" style="width: 15%" >รูปแบบ วิธีการพัฒนา</th>
          <th colspan="2" style="width: 10%" >วัน เดือน ปี ในการพัฒนา</th>
          <th rowspan="2" style="width: 5%" >แหล่งพัฒนา /แหล่งเรียนรู้</th>
          <th rowspan="2" style="width: 15%" >ผลที่คาดว่าจะได้รับ</th>
          <th rowspan="2" style="width: 10%" >หมายเหตุ</th>
        </tr>
        <tr>
          <th>เริ่มต้น</th>
          <th>สิ้นสุด</th>
        </tr>
        <tr class="row_data"  v-for="(results,index) in result" :key="index" >
          <td align="center" >{{ (index+1) }}</td>
          <td>{{ results.competence_develop }}</td>
          <td>{{ results.topic }}</td>
          <td>{{ results.start_date }}</td>
          <td>{{ results.end_date }}</td>
          <td align="center" >{{ results.development_learning_resources }}</td>
          <td>{{ results.expected_results }}</td>
          <td>{{ results.remark }}</td>
        </tr>
      </table>

    </div>





  </div>
</template>

<script>

import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {getData} from "../../lib/controller";
import {ResponseData} from "../../lib/utility";


export default {
  name: "PlanPrintHistory",
  components: {},
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    machine_item: {},
    machine_id: 0,
    valid: true,
    pincode: "",
    file: "",
    show1: false,
    show4: false,
    is_admin: false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],


    select: null,
    itemss: [],
    checkbox: false,

    baseUrl: baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      {text: 'ชื่อ', value: 'first_name'},
      {text: 'นามสกุล', value: 'last_name'},
      {text: 'แผนก', value: 'departments[0].title'},
      {text: 'RFID', value: 'rfidcode'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    resultData: [],

    activePicker: null,
    date: null,
    menu: false,

    name: "",
    school: "",
    amphur: "",
    province: "",
    class_detail: "",
    student_amount: "",
    course_select: 1,
    people_amount: 0,
    plan_detail: "",
    activity_plan: "",
    work_sheet: "",
    plan_problem_fix: "",

    cours_list: [
      {
        "label": "สังคมศึกษาศาสนาและวัฒนธรรม",
        "id": 1
      },
      {
        "label": "ภาษาไทย",
        "id": 2
      },
      {
        "label": "คอมพิวเตอร์ศึกษา",
        "id": 3
      }
    ],
    people_select: [],
    people_type_select: [],
    people_list: [
      {
        "label": "ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id": 1
      },
      {
        "label": "นายจักรพันธ์ แก้วกัณหา",
        "id": 2
      },
      {
        "label": "นางสาววราภรณ์ สุภาเฮือง",
        "id": 3
      }
    ],
    people_type: [
      {
        "label": "อาจารย์นิเทศ",
        "id": 1
      },
      {
        "label": "ครูพี่เลี้ยง",
        "id": 2
      },
      {
        "label": "นักศึกษา",
        "id": 3
      }
    ],

    time: null,
    menu2: false,
    modal2: false,


    users:JSON.parse(localStorage.getItem('user')),
  }),

  computed: {},
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },
  methods: {
    save(date) {
      this.$refs.menu.save(date)
    },
    initialize() {

    },
    Preview_image(e) {
      if (e) {
        this.file = e;
        $('#image_preview').attr('src', URL.createObjectURL(e)); // jQuery selector
      }
    },
    async GetData(){

      console.log(this)

      await getData(this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        Array.prototype.chunk = function(size) {
          let result = [];

          while(this.length) {
            result.push(this.splice(0, size));
          }

          return result;
        }

        switch (status){
          case 200:



            this.resultData = data.plan_detail.chunk(8);

            this.$refs.topProgress.done()
            break;
          default:


            break;
        }

      });
    },
  },
  async mounted() {
   await this.GetData()
  }
}


</script>

<style scoped>

@media screen{
  .page_print_landscape{
    /*display: none;*/
  }
  .table_list tr td{
    padding-left: 10px;
    font-weight: 600;
  }
  .description{
    margin-top:0.5rem;
    font-weight: 600;
    text-indent: 50px;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
  }
  .description_question{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 16px;
    height: 101px;
    text-align: initial;
  }
  .description_raw{
    margin-top:0.5rem;
    font-weight: 600;
    margin-bottom: 0px !important;
    /*max-height: 100px;*/
    text-align: initial;
    margin-left: 50px;
  }
  .table_list tr td:first-child{
    padding-left:0;
    text-align: center;
  }
  .horizontal_dotted_line {
    display: flex;
    padding: 5px;
    font-weight: bold;
    height: 2rem;
  }
  .horizontal_dotted_line:after {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .horizontal_dotted_line:before {
    border-bottom: 1px dotted black;
    content: '';
    flex: 1;
  }
  .table_list{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_history tr{
    background: #f1f1f1;
  }
  .table_list_des{
    margin-top: 2rem;
    margin-bottom: 2rem;
    border-collapse: collapse;
    width: 100%;
  }
  .table_list_des tr th{
    padding-block: 0.3rem;
  }
  .table_list_des tr td{
    padding-block: 0.3rem;
  }
  .row_print{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
  }
  .row_print_center{
    margin-top: 0.5rem;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }
  .row_print_des{
    margin-top: 0.5rem;
    display: flex;
    align-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1rem;
  }
  .page_print{
    font-family: 'Sarabun', sans-serif !important;
    width: 21cm;
    height: 29.7cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .page_print_landscape{
    font-family: 'Sarabun', sans-serif !important;
    width:  29.7cm;
    height: 21cm;
    padding: 2cm;
    padding-inline: 1cm;
    margin: 1cm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    margin-top: 0;
    padding-top: 0.5cm;
    line-height: 1.5 !important;
  }

  .header_top_report{
    display: none;
  }


  @import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

  body{
    font-family: 'Sarabun', sans-serif !important;
    font-size: 15px;
    background: white !important;
    line-height: 1.5;
  }

  .license_list{
    display: flex;
  }
  .license_item{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .header_custom{
    font-size: 2.2rem;
    font-weight: 300;
  }
  .header_sub_custom{
    font-size: 1.8rem;
    font-weight: 300;
  }
  .description_intro{
    margin-top:0.5rem;
    text-indent: 50px;
    margin-bottom: 0px !important;
    text-align: inherit;
    font-size: 1.2rem;
  }
  .row_data{
    background: none !important;
  }

  .row_data td{
    height: 4rem;
  }
  .list_check{
    line-height: 1.8rem;
  }
  .license_container{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 40px;
  }
}

@page {
  size: A4 landscape ;
}

/* Size in mm */
@page {
  size: 100mm 200mm ;
}

/* Size in inches */
@page {
  size: 4in 6in ;
}
</style>
