<template>
  <v-breadcrumbs :items="items">
    <template v-slot:item="{ item }">
      <v-breadcrumbs-item
          :to="item.href"
          :disabled="item.disabled"
      >
        {{ item.text.toUpperCase() }}
      </v-breadcrumbs-item>
    </template>
    <div class="card_container" >
      <div class="card_header" >
        <div>การสะท้อนการเรียนรู้ วงรอบที่ 1 </div>
      </div>

      <v-breadcrumbs v-if="!this.$route.params.id" :items="items">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
              :to="item.href"
              :disabled="item.disabled"
          >
            {{ item.text.toUpperCase() }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
      <div class="form_card" >
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >

          <v-row>
            <v-col
                cols="6"
                sm="5"
            >
            <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-model="date"
                    label="วันที่สะท้อนการจัดกิจกรรม"
                    prepend-icon="mdi-calendar"
                    readonly
                    :rules="[v => !!v || 'กรุณากรอกวันที่สะท้อนการจัดกิจกรรม']"
                    required
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="date"
                  :active-picker.sync="activePicker"

                  @change="save"
              ></v-date-picker>
            </v-menu>
            </v-col>
            <v-col
                cols="6"
                sm="5"
            >
              <v-menu
                  ref="menu"
                  v-model="menu2"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                      v-model="time"
                      label="เวลาที่สะท้อนกิจกรรม"
                      prepend-icon="mdi-clock-time-four-outline"
                      :rules="[v => !!v || 'กรุณากรอกเวลาที่สะท้อนกิจกรรม']"
                      required
                      readonly
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                    v-if="menu2"
                    v-model="time"
                    full-width
                    format="24hr"
                    no-title
                    @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>

          <v-text-field
              v-model="class_detail"
              :rules="[v => !!v || 'นักเรียนชั้น']"
              label="นักเรียนชั้น"
              required
          ></v-text-field>

          <v-text-field
              v-model="student_amount"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนนักเรียน"
              required
          ></v-text-field>


          <v-text-field
              v-model="departments"
              :rules="[v => !!v || 'รายวิชากลุ่มเรียนรู้']"
              label="รายวิชากลุ่มเรียนรู้"
              required
          ></v-text-field>


          <v-text-field
              v-model="people_amount"
              :rules="[v => !!v || 'จำนวน']"
              label="จำนวนผู้เข้าร่วม"
              required
          ></v-text-field>

          <div>
            <v-row v-if="people_amount !== 0" class="table_container" >
                <table class="custom_table" border="1" >
                  <tr>
                    <th>ลำดับ</th>
                    <th>ชื่อ-สกุล</th>
                    <th>ผู้ร่วม AAR</th>
                  </tr>
                  <tr v-for="index in parseInt(this.people_amount) " :key="index" >
                    <td>
                      {{ index }}
                    </td>
                    <td>
                      <v-text-field
                          v-model="name_visitor[index-1]"
                          :rules="[v => !!v || 'ชื่อ-นามสกุล']"
                          label="ชื่อ-นามสกุล"
                          required
                      ></v-text-field>
                    </td>
                    <td>
                      <v-text-field
                          v-model="type_visitor[index-1]"
                          :rules="[v => !!v || 'ประเภทผู้เข้าร่วม']"
                          label="ประเภทผู้เข้าร่วม"
                          required
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
            </v-row>
          </div>

          <h3 class="mb-5" >ประเด็นนำสะท้อนกิจกรรมการเรียนรู้ก่อนนำไปใช้สอน</h3>


          <v-textarea
              outlined
              label="1. องค์ประกอบของแผนจัดการเรียนรู้"
              v-model="plan_detail"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="2.การออกแบบจุดประสงค์การเรียนรู้ที่สอดคล้องกับกิจกรรมการเรียนรู้"
              v-model="plan_design"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>


          <v-textarea
              outlined
              label="3. กิจกรรมการเรียนรู้ที่ครูผู้สอนออกแบบมีความสอดคล้องตามตัวชี้วัด"
              v-model="activity_plan"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="4. ชิ้นงาน/ภาระงาน/การวัดประเมินผล สอดคล้องกับกิจกรรมการเรียนรู้"
              v-model="work_sheet"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-textarea
              outlined
              label="5. วิธีการแก้ไขปัญหาที่มีการวางแผนสู่การจัดกิจกรรมการเรียนรู้มีความเหมาะสม"
              v-model="plan_problem_fix"
              :rules="[v => !!v || 'กรุณากรอกข้อมูล']"
          ></v-textarea>

          <v-text-field
              v-model="total_hour"
              :rules="[value => (value !== '' && value > 0) || 'กรอกชั่วโมงเป็นตัวเลขเท่านั้น']"
              label="สรุปเวลา/ชั่วโมง (กรอกแค่ตัวเลข)"
              required
          ></v-text-field>

          <hr>



          <div v-if="is_loading" class="img-loading" >
            <img src="./../../assets/image/loading.svg" />
          </div>

          <div v-else align="center" style="margin-top: 2rem" >
            <div v-if="!this.$route.params.id" >
              <v-btn
                  class="mr-4 update_btn"
                  @click="validate"
              >
                บันทึก
              </v-btn>

              <v-btn
                  class="mr-4 reset_btn"
                  @click="reset"
              >
                ยกเลิก
              </v-btn><br>
              <br>
              <font v-if="check" style="font-size: 1rem;color: #fa0505;" >กรุณากรอกข้อมููลให้ครบ</font><br>

            </div>


          </div>

        </v-form>
      </div>

    </div>
  </v-breadcrumbs>



</template>

<script>
import {getData, getDepartment, postAAR1} from "../../lib/controller";
import {baseUrl} from "../../const/api";
import $ from 'jquery'
import {ResponseData} from "../../lib/utility";

export default {
  name: "Users",
  data: () => ({
    rules: [
      value => !value || value.size < 2000000 || 'Avatar size should be less than 2 MB!',
    ],
    checkInt:[
      value => !value || value > 0 || 'กรอกเวลาชัวเป็นตัวเลขอย่างเดียว',
    ],
    machine_item:{},
    machine_id:0,
    valid: true,
    pincode:"",
    file:"",
    show1: false,
    show4: false,
    is_admin:false,
    first_name: '',
    last_name: '',
    user_name: '',
    password: '',
    password_confirm: '',
    email: '',
    rfidcode: '',
    check:false,

    email_rules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
    ],


    department: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => (v && v.length <= 10) || 'Name must be less than 10 characters',
    ],



    select: null,
    itemss:[],
    checkbox: false,

    baseUrl:baseUrl,
    items: [
      {
        text: 'หน้าหลัก',
        disabled: false,
        href: '/',
      },
      {
        text: 'การสะท้อนการเรียนรู้ วงรอบที่ 1',
        disabled: true,
        href: '/aar_1',
      },
    ],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'รูปโปรไฟล์',
        align: 'start',
        sortable: false,
        value: 'image',
      },
      { text: 'ชื่อ', value: 'first_name' },
      { text: 'นามสกุล', value: 'last_name' },
      { text: 'แผนก', value: 'departments[0].title' },
      { text: 'RFID', value: 'rfidcode' },
      { text: 'Actions', value: 'actions', sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    resultData:[],

    activePicker: null,
    date: null,
    menu: false,

    name:"",
    school:"",
    amphur:"",
    province:"",
    class_detail:"",
    student_amount:"",
    department_select:1,
    people_amount:0,
    plan_detail:"",
    plan_design:"",
    activity_plan:"",
    work_sheet:"",
    plan_problem_fix:"",
    total_hour:"",
    departments:"",
    department_list:[
      {
        "label":"สังคมศึกษาศาสนาและวัฒนธรรม",
        "id":1
      },
      {
        "label":"ภาษาไทย",
        "id":2
      },
      {
        "label":"คอมพิวเตอร์ศึกษา",
        "id":3
      }
    ],
    people_select:[],
    people_type_select:[],
    people_list:[
      {
        "label":"ผศ.ดร.อรัญ ซุยกระเดื่อง",
        "id":1
      },
      {
        "label":"นายจักรพันธ์ แก้วกัณหา",
        "id":2
      },
      {
        "label":"นางสาววราภรณ์ สุภาเฮือง",
        "id":3
      }
    ],
    people_type:[
      {
        "label":"อาจารย์นิเทศ",
        "id":1
      },
      {
        "label":"ครูพี่เลี้ยง",
        "id":2
      },
      {
        "label":"นักศึกษา",
        "id":3
      }
    ],
    users:JSON.parse(localStorage.getItem('user')),
    time: null,
    menu2: false,
    modal2: false,
    name_visitor:[],
    type_visitor:[],
    is_loading: false,
  }),

  computed: {

  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.activePicker = 'YEAR'))
    },
  },

 async mounted () {
    this.initialize()
    await this.GetDepartment()
    await this.GetData()
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    initialize () {

    },
    validate () {
      console.log(this.file)
      if(this.$refs.form.validate()){
        this.check = false;
        this.Update();
      }else {
        this.check = true;
      }
    },
    reset () {
      this.$refs.form.reset()
      $('#image_preview').attr('src', '');
    },

    async GetDepartment(){
      await getDepartment((response)=>{

        const {status, data} = ResponseData(response)

        const department = [];

        switch (status){
          case 200:
              console.log(data)


              data.forEach((result)=>{
                department.push({
                  "id":result.id,
                  "label":result.name,
                })
              });

              this.department_list = department;

            break;
          default:


            break;
        }

      });
    },
    async GetData(){

      this.is_loading = true;
      console.log(this)

      await getData(this.$route.params.id ? this.$route.params.id : this.users.id,(response)=>{

        const {status, data} = ResponseData(response)

        const name_visitor = [];
        const type_visitor = [];

        switch (status){
          case 200:
           console.log(data.aar_1)

               this.is_loading = false;
               this.date = data.aar_1.date_activity;
               this.time = data.aar_1.time_activity;
               this.class_detail = data.aar_1.class_name;
               this.student_amount = data.aar_1.amount_student;
               this.department_select = data.aar_1.department;
               this.plan_detail = data.aar_1.plan_detail;
               this.plan_design = data.aar_1.plan_design;
               this.activity_plan = data.aar_1.activity_plan;
               this.work_sheet = data.aar_1.work_sheet;
               this.plan_problem_fix = data.aar_1.plan_problem_fix;
               this.total_hour = data.aar_1.total_hour;
               this.departments = data.aar_1.departments;
               this.student = data.data;




            data.visitor_aar1.forEach((result)=>{
              console.log(result)
              name_visitor.push(result.name)
              type_visitor.push(result.type)
            })

            this.people_amount = name_visitor.length;
            this.name_visitor = name_visitor;
            this.type_visitor = type_visitor;

            this.$refs.topProgress.done()

            break;
          default:
            this.is_loading = false;
            break;
        }

      });
    },
    async Update(){
      this.is_loading = false;
      let formData = new FormData();
      formData.append("student_id",this.users.id);
      formData.append("date_activity",this.date);
      formData.append("time_activity",this.time);
      formData.append("class_name",this.class_detail);
      formData.append("amount_student",this.student_amount);
      formData.append("department",this.department_select);
      formData.append("plan_detail",this.plan_detail);
      formData.append("plan_design",this.plan_design);
      formData.append("activity_plan",this.activity_plan);
      formData.append("work_sheet",this.work_sheet);
      formData.append("plan_problem_fix",this.plan_problem_fix);
      formData.append("departments",this.departments);
      formData.append("total_hour",this.total_hour);
      formData.append("name_visitor","-"+this.name_visitor);
      formData.append("type_visitor","-"+this.type_visitor);

      await postAAR1(formData,(response)=>{

        const {status, data} = ResponseData(response)

        switch (status){
          case 200:
              console.log(data)

            this.$swal({
              title: "สำเร็จ",
              text: "อัพเดทสำเร็จ",
              icon: "success",
              confirmButtonText: "ตกลง"
            }).then(async () => {
             await this.GetData();
            });

            this.is_loading = false;
            break;
          default:

            this.is_loading = false;

            break;
        }

      })

    }

  },
}


</script>

<style scoped>
.card_container{
  width: 100%;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #ffffff;
  background-clip: border-box;
  border: 1px solid #EBEDF3;
  border-radius: 0.42rem;
}
.card_header{
  padding: 1.5rem 2rem;
  margin-bottom: 0;
  background-color: #ffffff;
  border-bottom: 1px solid #EBEDF3;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form_card{
  padding:2rem;
}
.image_preview_container{
  display: flex;
  justify-content: center;
  align-items: center;
}
#image_preview{
  width: 20rem;
  text-align: center;
}
.custom_table{
  border-collapse: collapse;
}
.table_container{
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
</style>
